import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { css } from '@emotion/core';
import colors from '../colors';
import { Banner, Footer } from '../components';
import { LoginForm } from './LoginForm';
import { useLanguage } from '../utils';
import { ThankYouForRegistering } from './ThankYouForRegistering';

const LoginOrRegister = () => {
  const { enFr } = useLanguage();
  return (
    <Route
      render={({ history }) => (
        <>
          <div
            css={css`
              margin-top: 40px;
              @media screen and (max-width: 600px) {
                padding: 0 20px;
              }
            `}
          >
            <p
              css={css`
                font-size: 20px;
                font-weight: bold;
                color: ${colors.red};
              `}
            >
              {enFr(
                'Welcome to the CardioUroConnect.ca Portal!',
                'Bienvenue sur le portail CardioUroConnect.ca!',
              )}
            </p>
            <p
              css={css`
                font-size: 16px;
                color: ${colors.offBlack};
              `}
            >
              {enFr(
                `
                As a Canadian Urologist or Radiation Oncologist, having quick and
                ready access to cardiac screening services can be a key priority
                for many patients in your busy practice. With this free service,
                offered by Ferring Canada, you’ll be able to locate and connect
                with a nearby specialist. Through this expedited service, your patients can receive
                a timely cardiac assessment to help facilitate your clinical
                decision-making moving forward.
              `,
                `
                À titre d’urologue ou de radio-oncologue canadien, profiter d’un accès rapide et facile aux services de dépistage cardiaque peut être une priorité clé pour de nombreux patients de votre cabinet très occupé. Grâce à ce service gratuit, offert par Ferring Canada, vous serez en mesure de trouver et de joindre un spécialiste à proximité. Grâce à ce service accéléré, vos patients peuvent recevoir rapidement une évaluation cardiaque afin de faciliter la prise de décision clinique à l’avenir. 
              `,
              )}
            </p>
            <p
              css={css`
                font-size: 16px;
                color: ${colors.offBlack};
              `}
            >
              {enFr(
                <>
                  Please <b>Login</b> to access the directory or <b>Register</b>{' '}
                  a new account
                </>,
                <>
                  Veuillez <b>ouvrir une session</b> pour accéder au répertoire
                  ou vous <b>inscrire</b> pour enregistrer un nouveau compte.
                </>,
              )}
            </p>
          </div>

          <LoginForm />
          <div
            css={css`
              margin-top: 50px;
              margin-bottom: 40px;
              @media screen and (max-width: 600px) {
                padding: 0 20px;
              }
            `}
          >
            {enFr(
              `At Ferring, your privacy is important to us and we are committed to
              ensuring our data protection practices adhere to applicable Canadian
              and local national data protection regulations (see privacy page
              after logging in).`,
              `À Ferring, la protection de vos renseignements personnels est importante pour nous. Nous nous engageons à nous assurer que nos pratiques de protection des données sont conformes aux réglementations canadiennes et locales applicables en la matière (voir la page sur la protection des renseignements personnels après avoir ouvert une session).`,
            )}
          </div>
        </>
      )}
    />
  );
};

export const Login = props => {
  return (
    <div css={css``}>
      <Banner />
      <div
        className="content-wrapper"
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 50px;
          align-items: center;
          text-align: center;
          width: 600px;
          max-width: 100%;
          margin: 0 auto;
          min-height: calc(100vh - 240px);
        `}
      >
        <img
          css={css`
            width: 500px;
            height: 118px;
            max-width: 90%;
            margin-top: 30px;
          `}
          src={require('../assets/logo-outline.svg')}
          alt="CARDIOUROCONNECT.CA"
        />
        <Switch>
          <Route path="/" exact component={LoginOrRegister} />
          <Route path="/login" exact component={LoginOrRegister} />
          <Route
            path="/login/registered"
            exact
            component={ThankYouForRegistering}
          />
        </Switch>
      </div>
      <Footer
        css={css`
          margin-top: 40px;
        `}
      />
    </div>
  );
};
