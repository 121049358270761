import _ from 'lodash';
import React, { useMemo } from 'react';
import { css } from '@emotion/core';
import { Radio } from 'semantic-ui-react';
import * as firebase from 'firebase/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useUserActions } from './userHooks';
import { mailto } from '~/utils';
import BarLoader from 'react-spinners/BarLoader';

export const Users = () => {
  const { updateUser } = useUserActions();
  const [users, isLoading] = useCollectionData(
    useMemo(() => firebase.firestore().collection('users'), []),
    {
      idField: 'id',
    },
  );
  if (isLoading) {
    return (
      <div
        css={css`
          display: flex;
          height: 100%;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            align-self: center;
            margin-top: auto;
            margin-bottom: auto;
          `}
        >
          <BarLoader color="#dddddd" />
        </div>
      </div>
    );
  }
  return _.orderBy(users, x => x.createdAt, 'desc').map(user => {
    return (
      <div
        key={user.id}
        css={css`
          margin: 20px;
          padding: 0px 20px;
          padding-bottom: 20px;
          border-radius: 4px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04),
            0 -4px 8px 0 rgba(0, 0, 0, 0.04);

          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          & .row {
            display: flex;
            flex-direction: row;
          }
          & .column {
            display: flex;
            flex-direction: column;
          }
          .field {
            width: 75px;
            text-align: right;
            padding-right: 1em;
            font-weight: 600;
            font-size: 14px;
          }
        `}
      >
        <div
          className="row"
          css={css`
            align-items: center;
            flex-grow: 1;
            flex-wrap: wrap;
          `}
        >
          <div
            className="row"
            css={css`
              width: 200px;
            `}
          >
            <div className="field">Email:</div>
            <div>{user.email}</div>
          </div>
          <a
            css={css`
              margin-left: auto;
              padding: 1em;
            `}
            href={mailto({
              to: user.email,
              subject: `Your CardioUroConnect account has been approved and is now live`,
              body: [
                `Hi,`,
                ``,
                `Your CardioUroConnect account has been approved and is now live.`,
                ``,
                `Best regards,`,
                `Your Ferring Representative`,
              ].join('\n'),
            })}
            target="_blank"
            rel="noopener noreferrer"
          >
            Notify
          </a>
          <div
            className="row"
            css={css`
              flex-grow: 0;
              margin-left: 1em;
            `}
          >
            <div className="field">Approved:</div>
            <Radio
              toggle
              defaultChecked={user.isApproved}
              onChange={(e, data) =>
                updateUser(user.id, { isApproved: data.checked })
              }
            />
          </div>
          <div
            className="row"
            css={css`
              flex-grow: 0;
              margin-top: 1em;
              margin-left: 1em;
              margin-bottom: 1em;
            `}
          >
            <div className="field">Is Admin:</div>
            <Radio
              toggle
              defaultChecked={user.isAdmin}
              onChange={(e, data) =>
                updateUser(user.id, { isAdmin: data.checked })
              }
            />
          </div>
        </div>

        <div
          className="row"
          css={css`
            align-items: center;
            flex-grow: 1;
            flex-wrap: wrap;
          `}
        >
          <div className="row" css={css``}>
            <div className="field">Name:</div>
            <div>
              {user.firstname} {user.lastname}
            </div>
          </div>
        </div>

        <div
          className="row"
          css={css`
            margin-top: 10px;
            align-items: center;
            flex-grow: 1;
            flex-wrap: wrap;
          `}
        >
          <div className="row" css={css``}>
            <div className="field">Address:</div>
            <div>{user.address}</div>
          </div>
        </div>

        <div
          className="row"
          css={css`
            margin-top: 10px;
            align-items: center;
            flex-grow: 1;
            flex-wrap: wrap;
          `}
        >
          <div className="row" css={css``}>
            <div className="field">Created:</div>
            <div>
              {user.createdAt.toDate().toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </div>
          </div>
        </div>
      </div>
    );
  });
};
