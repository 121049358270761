import React from 'react';
import { css } from '@emotion/core';
import colors from '../colors';

export const Separator = props => (
  <div
    css={css`
      background-color: ${colors.red};
      height: 2px;
    `}
    {...props}
  />
);
