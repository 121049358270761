import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import * as firebase from 'firebase/app';
import { Link, Redirect } from 'react-router-dom';
import BarLoader from 'react-spinners/BarLoader';

export const HandleSignin = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    // Confirm the link is a sign-in with email link.
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      var email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then(function(result) {
          // Clear email from storage.
          setIsSuccess(true);
          setIsLoading(false);
          window.localStorage.removeItem('emailForSignIn');
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch(function(e) {
          setIsSuccess(false);
          setIsLoading(false);
          setError(e);
          console.log(e);
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }, []);

  return (
    <div
      css={css`
        display: flex;
        height: 100%;
        flex-direction: column;
      `}
    >
      <div
        css={css`
          align-self: center;
          margin-top: auto;
          margin-bottom: auto;
        `}
      >
        {isLoading && <BarLoader color="#dddddd" />}

        {isLoading && (
          <p
            css={css`
              margin-bottom: 1em;
            `}
          >
            Signing you in
          </p>
        )}

        {!isLoading && isSuccess && (
          <div
            css={css`
              text-align: center;
            `}
          >
            <p>You are now signed in </p>
            <p>
              <Link to="/">Return to home page</Link>
            </p>
          </div>
        )}
        {!isLoading && error && (
          <div
            css={css`
              text-align: left;
            `}
          >
            <p>
              We ran into an error: "<span>{error.code}</span>"
            </p>
            <pre
              css={css`
                max-width: 30em;
                white-space: pre-wrap;
                text-align: left;
              `}
            >
              {error.message}
            </pre>
            <p>
              <Link to="/">Return to home page</Link>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
