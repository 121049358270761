import { useMemo, useCallback, useEffect } from 'react';
import * as firebase from 'firebase/app';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { tracker } from '~/utils/tracker';
import constate from 'constate';

const db = firebase.firestore();

export const useAuth = () => {
  const [basicUser, initialising, error] = useAuthState(firebase.auth());

  const signup = useCallback((email, password) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  }, []);

  const login = useCallback((email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }, []);

  const logout = useCallback(() => {
    return firebase.auth().signOut();
  }, []);

  return {
    initialising,
    basicUser,
    signup,
    login,
    logout,
    error,
  };
};

export const [AuthProvider, useAuthContext] = constate(useAuth);

export const useUserData = uid => {
  const userDocReference = useMemo(
    () => uid && db.collection('users').doc(uid),
    [uid],
  );

  const [userFromDatabase, isLoadingUserFromDatabase] = useDocumentDataOnce(
    userDocReference,
  );

  useEffect(() => {
    tracker.set('uid', uid);
  }, [uid]);

  return [userFromDatabase, isLoadingUserFromDatabase];
};
