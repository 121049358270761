import * as firebase from 'firebase/app';
import 'firebase/firestore'; // Required for side-effects
import 'firebase/storage'; // Required for side-effects
import 'firebase/auth'; // Required for side-effects

const firebaseConfig = {
  apiKey: 'AIzaSyCoXN_b4csBvkYknrsP_JDohA98HrtSDJM',
  authDomain: 'cardiouroconnect.firebaseapp.com',
  databaseURL: 'https://cardiouroconnect.firebaseio.com',
  projectId: 'cardiouroconnect',
  storageBucket: 'cardiouroconnect.appspot.com',
  messagingSenderId: '429361893639',
  appId: '1:429361893639:web:4a3c1e35744b4115',
};

firebase.initializeApp(firebaseConfig);

// @ts-ignore
window.firebase = firebase;
