import jsonp from 'jsonp';
import _ from 'lodash';
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from 'react';
import { css } from '@emotion/core';
import { PageBanner } from './PageBanner';
import { useCardiologists, useLanguage } from '~/utils';
import { CardiologistListItem } from './CardiologistListItem';
import { useUserData, useAuth } from '~/auth';
import { Dropdown } from 'semantic-ui-react';
import InteractionStateContainer from './InteractionStateContainer';
import { ResultsMap } from './ResultsMap';
import { Footer } from '~/components';
import BarLoader from 'react-spinners/BarLoader';

export const provinceNames = {
  NL: 'Newfoundland and Labrador',
  PE: 'Prince Edward Island',
  NS: 'Nova Scotia',
  NB: 'New Brunswick',
  QC: 'Quebec',
  ON: 'Ontario',
  MB: 'Manitoba',
  SK: 'Saskatchewan',
  AB: 'Alberta',
  BC: 'British Columbia',
  YT: 'Yukon',
  NT: 'Northwest Territories',
  NU: 'Nunavut',
};

const ClinicSelectionInternal = () => {
  const { enFr } = useLanguage();
  const cityDropdownRef = useRef();
  const { businesses, setBusinesses } = useContext(
    InteractionStateContainer.Context,
  );
  const {
    cardiologists,
    isLoading: isLoadingCardiologists,
  } = useCardiologists();
  const [userCoordinates, setUserCoordinates] = useState({});
  const { basicUser, isLoading: isLoadingBasicUser } = useAuth();
  const [user, isLoadingUserFromDb] = useUserData(basicUser && basicUser.uid);
  const isLoading =
    isLoadingCardiologists || isLoadingUserFromDb || isLoadingBasicUser;
  const [provinceCodeFromGeolocation, setProvinceFromGeolocation] = useState();

  const latitude = userCoordinates.latitude;
  const longitude = userCoordinates.longitude;

  const [selectedProvinceCode, setSelectedProvinceCode] = useState('ON');
  const [selectedCity, setSelectedCity] = useState('_all_');

  const cardiologistsToMap = (cardiologists || []).filter(x => {
    const passesCityCheck =
      selectedCity === '_all_'
        ? true
        : _.get(x, 'structuredAddress.locality') === selectedCity;

    const passesProvinceCheck =
      selectedProvinceCode === _.get(x, 'structuredAddress.adminDistrict');
    return passesCityCheck && passesProvinceCheck;
  });

  useEffect(
    () => {
      setBusinesses(cardiologists);
    },
    // eslint-disable-next-line
    [
      JSON.stringify(cardiologists), // eslint-disable-line
    ],
  );

  useEffect(() => {
    if (!latitude) {
      navigator.geolocation.getCurrentPosition(position => {
        setUserCoordinates({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });

        jsonp(
          `https://dev.virtualearth.net/REST/v1/Locations/${position.coords.latitude},${position.coords.longitude}?maxResults=1&key=${process.env.REACT_APP_BING_MAP_KEY}`,

          {
            param: 'jsonp',
          },
          (err, data) => {
            if (err) {
              console.error(err);
            }

            setProvinceFromGeolocation(
              _.get(data, 'resourceSets[0].resources[0].address.adminDistrict'),
            );
          },
        );
      });
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (!provinceCodeFromGeolocation) {
      return;
    }
    setSelectedProvinceCode(provinceCodeFromGeolocation);
    provinceSelectRef.current &&
      provinceSelectRef.current.setValue(provinceCodeFromGeolocation);
  }, [provinceCodeFromGeolocation]);

  const provinceSelectRef = useRef();

  const cardiologistsGroupedByProvinceCode = _.groupBy(cardiologists, x =>
    _.get(x, 'structuredAddress.adminDistrict'),
  );

  const cardiologistsGroupedByCity = _.groupBy(
    cardiologistsGroupedByProvinceCode[selectedProvinceCode],
    x => _.get(x, 'structuredAddress.locality'),
  );

  const cities = Object.keys(cardiologistsGroupedByCity);

  if (isLoading || !user) {
    return (
      <div
        css={css`
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          height: calc(100% - 110px);
        `}
      >
        <div
          css={css`
            align-self: center;
            margin-top: auto;
            margin-bottom: auto;
          `}
        >
          <BarLoader color="#dddddd" />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div
        css={css`
          margin-left: auto;
          margin-right: auto;
          width: 100%;
          max-width: 1000px;
          display: flex;
          flex-direction: row;
          position: relative;
          z-index: 11;
        `}
      >
        <div
          css={css`
            padding-top: 30px;
            padding-bottom: 30px;
            padding-left: 10px;
          `}
        >
          <div
            css={css`
              font-size: 20px;
              font-weight: 600;
              color: #971f38;
            `}
          >
            {enFr('Clinic Selection', 'Sélection de cliniques')}
          </div>
          <div
            css={css`
              margin-top: 10px;
              font-weight: 600;
              font-size: 13px;
            `}
          >
            {enFr(
              `Please select the Province and City to locate a referring specialist`,
              `Sélectionnez la province et la ville pour trouver un spécialiste traitant.`,
            )}
          </div>

          <div
            css={css`
              margin-top: 20px;
            `}
          >
            <Dropdown
              selection
              options={Object.keys(cardiologistsGroupedByProvinceCode).map(
                provinceCode => ({
                  key: provinceCode,
                  value: provinceCode,
                  text: provinceNames[provinceCode],
                }),
              )}
              defaultValue={selectedProvinceCode}
              onChange={(e, data) => {
                setSelectedProvinceCode(data.value);
                setSelectedCity('_all_');
                cityDropdownRef.current.setValue('_all_');
              }}
              ref={provinceSelectRef}
            />
          </div>
          <div
            css={css`
              margin-top: 20px;
            `}
          >
            <Dropdown
              ref={cityDropdownRef}
              selection
              options={[
                {
                  key: '_all_',
                  value: '_all_',
                  text: <span>{enFr('All', 'Tous')}</span>,
                },
                ...cities.map(city => ({
                  key: city,
                  value: city,
                  text: <span>{city}</span>,
                })),
              ]}
              defaultValue="_all_"
              onChange={(e, data) => {
                setSelectedCity(data.value);
              }}
            />
          </div>
        </div>
      </div>
      <div
        css={css`
          margin-left: auto;
          margin-right: auto;
          width: 100%;
          max-width: 1000px;
          display: flex;
          flex-direction: row;
        `}
      >
        <div
          className="left-side"
          css={css`
            width: 460px;
            @media screen and (max-width: 600px) {
              max-width: 100%;
            }
          `}
        >
          <div
            css={css`
              background-color: #f9f9f9;
            `}
          >
            <div
              css={css`
                position: sticky;
                top: 100px;
                padding-top: 20px;
                background-color: #ffffff;
                z-index: 10;
                @media screen and (max-width: 600px) {
                  position: relative;
                  top: 0;
                }
              `}
            >
              <div
                css={css`
                  color: #ffffff;
                  font-size: 24px;
                  font-weight: 600;
                  background-image: url(${require('~/assets/province_banner.png')});
                  background-size: auto 100%;
                  background-repeat: repeat-x;
                  padding-left: 20px;
                  height: 105px;
                  display: flex;
                  align-items: center;
                  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
                `}
              >
                {provinceNames[selectedProvinceCode]}
              </div>
            </div>
            <div
              css={css`
                padding-left: 20px;
                @media screen and (max-width: 600px) {
                  padding-left: 0px;
                }

                overflow-y: auto;
                overflow-x: hidden;
              `}
            >
              {_.map(
                _.pickBy(
                  _.groupBy(
                    cardiologistsGroupedByProvinceCode[selectedProvinceCode],
                    x => _.get(x, 'structuredAddress.locality'),
                  ),
                  (x, city) => {
                    if (selectedCity === '_all_') {
                      return true;
                    }
                    return selectedCity === city;
                  },
                ),
                (cardiologists, city, i) => {
                  return (
                    <div
                      key={city}
                      css={css`
                        & + & {
                          position: relative;
                          padding-top: 50px;
                          &:before {
                            content: '';
                            position: absolute;
                            top: 40px;
                            width: calc(100% - 60px);
                            height: 2px;
                            left: 0;
                            right: 0;
                            margin: auto;
                            background-color: #8a2a3a;
                          }
                        }
                      `}
                    >
                      <div
                        css={css`
                          font-weight: 600;
                          font-size: 16px;
                          margin-top: 20px;
                          margin-bottom: 10px;

                          @media screen and (max-width: 600px) {
                            padding-left: 20px;
                          }
                        `}
                      >
                        {city}
                      </div>
                      <div>
                        {cardiologists.map(x => (
                          <CardiologistListItem
                            key={x.id}
                            className="CardiologistListItem"
                            cardiologist={x}
                            userAddressString={`Dr. ${user.firstname} ${user.lastname}\n${user.address}`}
                            css={css`
                              display: flex;
                              width: 440px;
                              @media screen and (max-width: 600px) {
                                max-width: 100%;
                              }
                              & + .CardiologistListItem {
                                margin-top: 10px;
                              }
                            `}
                          />
                        ))}
                      </div>
                    </div>
                  );
                },
              )}
            </div>
          </div>
        </div>
        <div
          className="RM-container"
          css={css`
            flex-grow: 1;
            padding-left: 10px;
            max-height: 60vh;
            position: sticky;
            top: 100px;
            padding-top: 20px;
          `}
        >
          {selectedProvinceCode && (
            <ResultsMap businesses={cardiologistsToMap} />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export const ClinicSelection = () => {
  return (
    <InteractionStateContainer.Provider>
      <ClinicSelectionInternal />
    </InteractionStateContainer.Provider>
  );
};
