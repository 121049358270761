import React from 'react';
import { Redirect } from 'react-router-dom';
import { css } from '@emotion/core';
import { Switch, Route } from 'react-router-dom';
import { Cardiologists } from './Cardiologists';
import { Sidebar as AdminSidebar } from './Sidebar';
import { Users } from './Users';

export const Admin = () => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        font-family: Lato;
        height: 100%;
      `}
    >
      <AdminSidebar />
      <div
        css={css`
          background-color: #f9fafc;
          flex-grow: 1;
          overflow: auto;
        `}
      >
        <Switch>
          <Route path="/admin/specialists" exact component={Cardiologists} />
          <Route path="/admin/users" exact component={Users} />
          <Route render={() => <Redirect to="/admin/users" />} />
        </Switch>
      </div>
    </div>
  );
};
