import React from 'react';
import { NavLink } from 'react-router-dom';
import { css } from '@emotion/core';
import { useLanguage } from '~/utils';

export const Footer = ({ children, ...props }) => {
  const { enFr } = useLanguage();
  return (
    <div
      css={css`
        height: 100px;
        margin-top: 20px;

        background-color: #e6e3df;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 -3px 3px 0 rgba(0, 0, 0, 0.1);

        color: #9e9e9e;
        text-align: center;
        font-size: 12px;

        flex-direction: column;
        @media screen and (max-width: 600px) {
          padding: 0 20px;
        }
      `}
      {...props}
    >
      {/* <div
        css={css`
          a {
            color: inherit;
          }
        `}
      >
        <a
          css={enFr(
            css`
              font-weight: bold;
            `,
            css`
              cursor: pointer;
            `,
          )}
          onClick={() => setLanguage('en')}
        >
          English
        </a>{' '}
        |{' '}
        <a
          css={enFr(
            css`
              cursor: pointer;
            `,
            css`
              font-weight: bold;
            `,
          )}
          onClick={() => setLanguage('fr')}
        >
          French
        </a>
      </div> */}
      {enFr(
        'Please note that this portal is intended for Health Care Practitioners only.',
        'Veuillez noter que ce portail est réservé exclusivement aux professionnels de la santé.',
      )}

      <br />
      {enFr(
        '© 2019 Ferring Inc. All rights reserved | Last updated: Aug 20, 2019| UY/2014/2019/CA',
        '© Ferring Inc., 2019. Tous droits réservés | Dernière mise à jour : 20 août 2019 | UY/2014/2019/CA',
      )}
    </div>
  );
};
