import { useMemo } from 'react';
import * as firebase from 'firebase/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';

export const useCardiologists = () => {
  const [cardiologists, isLoading] = useCollectionData(
    useMemo(() => firebase.firestore().collection('cardiologists'), []),
    {
      idField: 'id',
    },
  );

  return { cardiologists, isLoading };
};
