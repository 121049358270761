import React from 'react';
import { NavLink } from 'react-router-dom';
import { css } from '@emotion/core';
import { Banner } from '~/components';
import { useUserData, useAuth } from '~/auth';
import { useLanguage } from '~/utils';

const Separator = props => (
  <div
    css={css`
      width: 1px;
      background-color: #ffffff;
      height: 18px;
      margin-left: 20px;
      margin-right: 20px;
    `}
    {...props}
  />
);

export const PageBanner = props => {
  const { enFr } = useLanguage();
  const { basicUser, initialising: initialisingAuth } = useAuth();
  const [userFromDatabase, isLoadingUser] = useUserData(
    basicUser && basicUser.uid,
  );

  return (
    <Banner {...props}>
      <div
        css={css`
          margin-left: auto;
          margin-right: auto;
          max-width: 1000px;
          display: flex;
          flex-direction: row;
        `}
      >
        <NavLink
          to="/"
          css={css`
            @media screen and (max-width: 600px) {
              width: 140px;
              position: absolute;
              display: block;
              top: 4px;
              left: 0px;
            }
          `}
        >
          <img
            css={css`
              height: 70px;
              margin-top: 10px;
              padding-left: 20px;
              @media screen and (max-width: 600px) {
                width: 100%;
                height: auto;
              }
            `}
            src={require('~/assets/logo-filled.svg')}
            alt=""
          />
        </NavLink>

        <div
          css={css`
            margin-left: auto;
            padding-right: 20px;
            display: flex;
            align-items: center;
            color: #ffffff;

            padding-top: 30px;

            @media screen and (max-width: 600px) {
              margin-top: -6px;
            }
          `}
        >
          {userFromDatabase && userFromDatabase.isAdmin && (
            <>
              <NavLink
                css={css`
                  color: #ffffff;
                  &:hover {
                    color: #ffffff;
                    text-decoration: underline;
                  }
                `}
                to="/admin"
              >
                Admin
              </NavLink>
              <Separator />
            </>
          )}
          <NavLink
            to="/clinics"
            activeStyle={{
              fontWeight: 600,
              textDecoration: 'none',
            }}
            css={css`
              color: #ffffff;
              &:hover {
                color: #ffffff;
                text-decoration: underline;
              }
            `}
          >
            {enFr('Clinics', 'Cliniques')}
          </NavLink>
          <Separator />
          <NavLink
            to="/privacy"
            activeStyle={{
              fontWeight: 600,
              textDecoration: 'none',
            }}
            css={css`
              color: #ffffff;
              &:hover {
                color: #ffffff;
                text-decoration: underline;
              }
            `}
          >
            {enFr('Privacy', 'Confidentialité')}
          </NavLink>
          <Separator />

          <NavLink
            css={css`
              color: #ffffff;
              &:hover {
                color: #ffffff;
                text-decoration: underline;
              }
            `}
            to="/logout"
          >
            {enFr('Log out', 'Fermer la session')}
          </NavLink>
        </div>
      </div>
    </Banner>
  );
};
