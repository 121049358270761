import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/core';
import { Button as CardioButton, Banner, Footer } from '../components';
import colors from '../colors';
import { mailto, useLanguage } from '~/utils';
import { Button, Form } from 'semantic-ui-react';
import { useAuth, useUserData } from '~/auth';
import { useUserActions } from '~/Admin/Users/userHooks';
import useForm from 'react-hook-form';

export const ThankYouForRegistering = () => {
  const { enFr } = useLanguage();
  // const [firstname, setFirstname] = useState('');
  // const [lastname, setLastname] = useState('');
  // const [address, setAddress] = useState('');
  // const [registrationNumber, setRegistrationNumber] = useState();

  const { basicUser } = useAuth();
  const [user, isLoadingUserFromDb] = useUserData(basicUser && basicUser.uid);
  const { updateUser } = useUserActions();
  const { register, handleSubmit, errors, reset, setValue } = useForm({
    mode: 'onBlur',
  });

  const setValuesFromDb = useCallback(
    () => {
      if (!user) {
        return;
      }
      setValue('firstname', user.firstname || '');
      setValue('lastname', user.lastname || '');
      setValue('address', user.address || '');
      setValue('registration', user.registration || '');
    },
    // eslint-disable-next-line
    [user && user.email],
  );

  useEffect(() => {
    if (isLoadingUserFromDb) {
      return;
    }
    setValuesFromDb();
  }, [isLoadingUserFromDb, setValuesFromDb]);

  if (isLoadingUserFromDb) {
    return (
      <div>
        <Banner />
      </div>
    );
  }

  return (
    <div css={css``}>
      <Banner />
      <div
        className="content-wrapper"
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 50px;
          align-items: center;
          text-align: center;
          width: 600px;
          max-width: 100%;
          margin: 0 auto;
        `}
      >
        <img
          css={css`
            width: 500px;
            height: 118px;
            max-width: 90%;
            margin-top: 30px;
          `}
          src={require('../assets/logo-outline.svg')}
          alt="CARDIOUROCONNECT.CA"
        />
        <div
          css={css`
            margin-top: 40px;
          `}
        >
          <p
            css={css`
              font-size: 20px;
              font-weight: bold;
              color: ${colors.red};
            `}
          >
            {enFr(
              'Thank you for registering for the CardioUroConnect.ca Portal!',
              'Merci de votre inscription sur le portail CardioUroConnect.ca!',
            )}
          </p>
          <p
            css={css`
              font-size: 16px;
              color: ${colors.offBlack};
            `}
          >
            {enFr(
              `Please fill out the form below to send us an email requesting access
              to the portal.`,
              `Veuillez remplir le formulaire ci-dessous pour nous envoyer un courriel afin de nous demander un accès au portail.`,
            )}
          </p>
          <p
            css={css`
              font-size: 16px;
              color: ${colors.offBlack};
            `}
          >
            {enFr(
              `
              Once we review and verify your information, Ferring will activate your account.
              `,
              `Une fois que nous aurons examiné et vérifié vos renseignements, Ferring activera votre compte. `,
            )}
            <br />
          </p>
          <div
            css={css`
              background-color: #f9f9f9;
              border-radius: 2px;
              margin-top: 30px;
              padding: 30px;
              width: 600px;
              max-width: 100%;
            `}
          >
            <Form
              onSubmit={handleSubmit(({ registration, ...data }) => {
                updateUser(basicUser.uid, data);
                window.open(
                  mailto({
                    to: `register@cardiouroconnect.ca`,
                    subject: enFr(
                      `CardioUroConnect.ca Registration`,
                      `Inscription à CardioUroConnect.ca`,
                    ),
                    body: enFr(
                      `Please verify the following details for accuracy before you hit "send".
      
First Name: ${data.firstname}

Last Name: ${data.lastname}

Address (Unit/Suite, Street, City, Province, and Postal Code):
${data.address}

Health Care Practitioner Registration Number: ${registration}
      `,
                      `Vérifiez l’exactitude des renseignements suivants avant de cliquer sur « Envoyer ».

Prénom : ${data.firstname}
Nom : ${data.lastname}
Adresse (unité/bureau, rue, ville, province, et code postal) : ${data.address}
Numéro d’enregistrement de professionnel de la santé : ${registration}
      `,
                    ),
                  }),
                  '_blank',
                );
              })}
              css={css`
                margin-bottom: 2em;

                .field {
                  display: flex;
                  justify-content: flex-start;
                  align-items: flex-start;
                }
                & label {
                  width: 250px !important;
                  text-align: right;
                  padding-right: 1em;
                }
                & input {
                  /* width: 200px !important; */
                }
              `}
            >
              <Form.Field
                css={css`
                  flex-grow: 1;
                `}
              >
                <label>{enFr(`First Name`, `Prénom`)}</label>
                <input name="firstname" ref={register} />
              </Form.Field>
              <Form.Field
                css={css`
                  flex-grow: 1;
                `}
              >
                <label>{enFr(`Last Name`, `Nom`)}</label>
                <input name="lastname" ref={register} />
              </Form.Field>

              <Form.Field
                css={css`
                  display: flex;
                  label {
                    padding-right: 0.5em;
                  }
                `}
              >
                <label>{enFr(`Address`, `Adresse`)}</label>
                <textarea rows="3" name="address" ref={register} />
              </Form.Field>

              <Form.Field
                css={css`
                  display: flex;
                  label {
                    padding-right: 0.5em;
                  }
                `}
              >
                <label
                  css={css`
                    font-size: 12px !important;
                    line-height: 1.2;
                  `}
                >
                  <label>
                    {enFr(
                      `Health Care Practitioner Registration Number`,
                      `Numéro d’enregistrement de professionnel de la santé`,
                    )}
                  </label>
                </label>
                <input name="registration" ref={register} />
              </Form.Field>

              <CardioButton
                type="submit"
                css={css`
                  width: 360px;
                  margin-top: 20px !important;
                  && {
                    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.4);
                    padding-top: 14px;
                    padding-bottom: 14px;
                  }
                `}
              >
                {enFr(
                  `Contact Ferring Representative`,
                  'Communiquer avec un représentant Ferring',
                )}
              </CardioButton>
            </Form>

            <p
              css={css`
                font-size: 11px;
                line-height: 1.3;
                color: #999;
                margin-top: 0px;
              `}
            >
              {enFr(
                `Please note that this portal is intended for a Health Care
                Practitioner audience only`,
                `Veuillez noter que ce portail est réservé exclusivement à un public de professionnels de la santé.`,
              )}
            </p>
          </div>

          <Button
            basic
            css={css`
              border: none !important;
              display: block;
              margin-top: 30px !important;
              margin-bottom: 30px !important;
              &:not(:hover) {
                box-shadow: none !important;
              }
            `}
            as={Link}
            to="/logout"
          >
            {enFr(
              `Return to Login Page`,
              `Retourner à la page d’ouverture de session`,
            )}
          </Button>
        </div>
      </div>
      <Footer
        css={css`
          margin-top: 40px;
        `}
      />
    </div>
  );
};
