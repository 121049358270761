import React, { useRef, useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import { css } from '@emotion/core';
import ReactMapGL, { Marker, NavigationControl } from 'react-map-gl';
import WebMercatorViewport from 'viewport-mercator-project';
import useComponentSize from '@rehooks/component-size';

import InteractionStateContainer from '../InteractionStateContainer';

import Pin from './Pin';
import Info from './Info';

const ResultsMap = ({ businesses, className }) => {
  let ref = useRef(null);
  let size = useComponentSize(ref);
  const hasSize = size.width !== 0;

  const {
    businessIdInFocus,
    focusOnBusinessId,
    selectedBusinessId,
    setSelectedBusinessId,
  } = useContext(InteractionStateContainer.Context);

  const viewport = new WebMercatorViewport({
    width: size.width,
    height: size.height,
  });

  const minLong = _.min(businesses.map(business => business.coordinates._long));
  const minLat = _.min(businesses.map(business => business.coordinates._lat));
  const maxLong = _.max(businesses.map(business => business.coordinates._long));
  const maxLat = _.max(businesses.map(business => business.coordinates._lat));

  const bounds = [
    [
      minLong !== maxLong ? minLong : minLong - 0.011,
      minLat !== maxLat ? minLat : minLat - 0.011,
    ],
    [
      minLong !== maxLong ? maxLong : minLong + 0.011,
      minLat !== maxLat ? maxLat : minLat + 0.011,
    ],
  ];
  const fittedViewport = viewport.fitBounds(
    bounds,
    hasSize
      ? {
          padding: 40,
          // offset: [0, -100]
        }
      : {},
  );

  const [viewportState, setViewportState] = useState(fittedViewport);

  useEffect(
    () => {
      setViewportState(fittedViewport);
    },
    // eslint-disable-next-line
    [JSON.stringify(businesses), hasSize],
  );

  return (
    <div
      ref={ref}
      className="ResultsMap "
      css={css`
        width: 100%;
        height: 100%;
      `}
    >
      {hasSize && (
        <ReactMapGL
          className={className}
          width="100%"
          height="100%"
          viewState={viewportState}
          onViewportChange={viewport => setViewportState(viewport)}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
          mapStyle="mapbox://styles/garnwraly/cjsidduzq1bc11flev56ws8gp"
          scrollZoom={false}
        >
          <div
            style={{ position: 'absolute', right: 10, bottom: 46, zIndex: 1 }}
          >
            <NavigationControl showCompass={false} />
          </div>
          {businesses.map(business => (
            <React.Fragment key={business.id}>
              <Marker
                latitude={business.coordinates._lat}
                longitude={business.coordinates._long}
                offsetLeft={-20}
                offsetTop={-10}
              >
                <Pin
                  isDeemphasized={
                    !!businessIdInFocus && businessIdInFocus !== business.id
                  }
                  onMouseEnter={() => focusOnBusinessId(business.id, 'map')}
                  onMouseLeave={() => focusOnBusinessId(null, 'map')}
                  onClick={() =>
                    setSelectedBusinessId(
                      selectedBusinessId === business.id ? null : business.id,
                    )
                  }
                />
              </Marker>
              {selectedBusinessId === business.id && (
                <Info businessId={business.id} />
              )}
            </React.Fragment>
          ))}
        </ReactMapGL>
      )}
    </div>
  );
};

export default ResultsMap;
