import React, { useContext } from 'react';
import { css } from '@emotion/core';
import { Popup } from 'react-map-gl';

import InteractionStateContainer from '../InteractionStateContainer';

const Info = ({ businessId }) => {
  const { businesses, setSelectedBusinessId } = useContext(
    InteractionStateContainer.Context,
  );

  const business = businesses.find(x => x.id === businessId);

  return (
    <Popup
      tipSize={5}
      anchor="bottom"
      longitude={business.coordinates._long}
      latitude={business.coordinates._lat}
      closeOnClick={false}
      onClose={() => setSelectedBusinessId(null)}
      css={css`
        z-index: 10;
        margin-left: -10px;
        margin-top: -10px;
      `}
    >
      <div>
        <h4
          css={css`
            margin: 0 0 0.2em;
          `}
        >
          {business.name}
        </h4>
        <address
          css={css`
            white-space: pre-line;
          `}
        >
          {business.address}
        </address>
      </div>
    </Popup>
  );
};

export default Info;
