import React from 'react';
import { css } from '@emotion/core';
// import colors from '../colors';
import { Button as SemanticUIButton } from 'semantic-ui-react';

export const Button = props => (
  <SemanticUIButton
    css={css`
      && {
        background-color: #971f38;
        color: #ffffff;
        font-weight: 400;
        &:focus {
          color: #ffffff;
          background-color: #971f38;
          box-shadow: 0 0 8px 2px #999;
        }
        &:hover {
          color: #ffffff;
          background-color: #ce2845;
        }
      }
    `}
    {...props}
  />
);
