import './initFirebase';
import css from '@emotion/css';
import React from 'react';
import ReactDOM from 'react-dom';

import 'semantic-ui-css/semantic.min.css';
import './index.css';

import App from './App';
import { useLanguage } from './utils';

const start = () => {
  if (true || window.location.host.includes('netlify.com')) {
    if (!sessionStorage.getItem('passedPrompt')) {
      if (prompt('Please enter code to preview site') !== 'welcome') {
        ReactDOM.render(
          <div
            css={css`
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            code required to preview site
          </div>,
          document.getElementById('root'),
        );
        return;
      }
      sessionStorage.setItem('passedPrompt', true);
    }
  }

  ReactDOM.render(
    <React.Suspense fallback={<div>...loading...</div>}>
      <useLanguage.Provider>
        <App />
      </useLanguage.Provider>
    </React.Suspense>,
    document.getElementById('root'),
  );
};

start();
