import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export const generatePdf = async element => {
  const canvas = await html2canvas(element);

  const image = new Image();
  image.src = canvas.toDataURL();

  return new Promise(resolve => {
    image.onload = () => {
      const { naturalWidth, naturalHeight } = image;

      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: 'letter',
      });

      const docWidth = doc.internal.pageSize.getWidth();
      const pdfImageHeight = naturalHeight * (docWidth / naturalWidth);

      doc.addImage(image, 'PNG', 0, 0, docWidth, pdfImageHeight);
      doc
        .save('requisition_form.pdf', { returnPromise: true })
        .then(() => resolve());
    };
  });
};
