import React, { useState, useCallback, useEffect, useRef } from 'react';
import { css, keyframes } from '@emotion/core';
import { Form, Button } from 'semantic-ui-react';
import useForm from 'react-hook-form';
import { ReactComponent as UploadImageIcon } from '@fortawesome/fontawesome-pro/svgs/light/camera.svg';
import { ReactComponent as SpinnerIcon } from '@fortawesome/fontawesome-pro/svgs/duotone/spinner.svg';

import * as firebase from 'firebase/app';
import { useFileUpload } from '../../utils/useFileUpload';
import { useLocationByQuery } from '../../utils/useLocationByQuery';

function handleNameError(errors) {
  if (!errors.name) {
    return;
  }
  if (errors.name && errors.name.type === 'required') {
    return `Required`;
  }
}

const getBboxArea = bbox => {
  const [x1, y1, x2, y2] = bbox;
  return (x2 - x1) * (y2 - y1);
};

function handleAddressError(errors) {
  if (!errors.address) {
    return;
  }
  const errorType = errors.address.type;
  switch (errorType) {
    case 'required':
      return `Required`;
    case 'parsable':
      return `Could not be parsed`;
    case 'unambiguous':
      return `Could not find address`;
    case 'precise':
      return `Needs to be more precise`;
    default:
      return 'Invalid';
  }
}

const ErrorMessageSpan = props => (
  <span
    css={css`
      font-size: 0.8em;
      color: #8e1e1e;
    `}
    {...props}
  />
);

export const CardiologistForm = ({
  initialValues,
  onSubmit,
  onDelete,
  submitText,
  mode,
  ...props
}) => {
  initialValues = initialValues || {};

  const [isFormDisabled, setIsFormDisabled] = useState(mode !== 'create');

  const [profileImage, setProfileImage] = useState(
    initialValues.profileImage || '',
  );
  const [address, setAddress] = useState(initialValues.address || '');
  const { structuredAddress, coords, isLoading } = useLocationByQuery(address, {
    noop: isFormDisabled,
  });

  // validation callback will use scope value otherwise (won't ever update)
  const structuredAddressRef = useRef(structuredAddress || {});
  useEffect(() => {
    structuredAddressRef.current = structuredAddress;
  }, [structuredAddressRef, structuredAddress]);

  const [isUploading, setIsUploading] = useState(false);
  const { register, handleSubmit, errors, reset } = useForm({
    mode: 'onBlur',
  });
  const [uploadFile] = useFileUpload('profile_pics');

  const handleFileUpload = async file => {
    setIsUploading(true);
    const uploadedFileUrl = await uploadFile(file, `profile_pics/${file.name}`);
    setIsUploading(false);
    setProfileImage(uploadedFileUrl);
  };

  const HandleNameError = () => {
    return <ErrorMessageSpan>{handleNameError(errors)}</ErrorMessageSpan>;
  };

  const HandleAddressError = () => {
    return <ErrorMessageSpan>{handleAddressError(errors)}</ErrorMessageSpan>;
  };

  const profileImageWidth = 150;

  return (
    <Form
      size="small"
      css={css`
        display: flex;
        flex-direction: row;
        border-radius: 4px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04),
          0 -4px 8px 0 rgba(0, 0, 0, 0.04);

        &&& .field {
          display: flex;
          label {
            width: 100px;
            text-align: right;
            padding-right: 10px;
            font-size: 14px;
            padding: 0.5em 1em;
          }
          input,
          textarea {
            padding: 0.5em 0.5em;
          }
          input:disabled,
          textarea:disabled {
            opacity: 1;
            background-color: transparent;
            border: 1px solid transparent;
            transform: translate(-6px, 0);
          }
        }
      `}
      onSubmit={handleSubmit(formData => {
        onSubmit({
          ...formData,
          profileImage,
          structuredAddress: structuredAddress && structuredAddress.address,
          coordinates:
            structuredAddress &&
            new firebase.firestore.GeoPoint(
              ...structuredAddress.geocodePoints[0].coordinates,
            ),
        });
        if (mode === 'edit') {
          setIsFormDisabled(true);
        }
        if (mode === 'create') {
          reset();
        }
      })}
      {...props}
    >
      <Form.Field
        css={css`
          position: relative;
          min-width: ${profileImageWidth}px;
          &&& {
            margin-right: 20px;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          <img
            src={
              profileImage ? profileImage : require('~/assets/default_icon.png')
            }
            alt=""
            css={css`
              width: ${profileImageWidth}px;
              height: ${profileImageWidth}px;
              object-fit: cover;
              border-radius: 50%;
            `}
          />
          {initialValues.createdAt && (
            <div
              css={css`
                margin-top: auto;
              `}
            >
              Created{' '}
              {initialValues.createdAt.toDate().toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </div>
          )}
        </div>
        <div
          css={css`
            width: ${profileImageWidth}px;
            height: ${profileImageWidth}px;
            border-radius: 50%;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            ${isUploading &&
              css`
                opacity: 0;
                pointer-events: none;
              `}

            &:hover {
              background-image: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0.4),
                rgba(0, 0, 0, 0.7)
              );
              .upload-icon {
                opacity: 1;
              }
            }
          `}
        >
          <UploadImageIcon
            className="upload-icon"
            css={css`
              width: 50px;
              opacity: 0;
              transition: 0.1s opacity;
              fill: #ffffff;
            `}
          />
          <input
            css={css`
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer;
            `}
            type="file"
            accept="image/*"
            onChange={e => {
              handleFileUpload(e.target.files[0]);
            }}
          />
        </div>

        {isUploading && (
          <div
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              width: ${profileImageWidth}px;
              height: ${profileImageWidth}px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: rgba(0, 0, 0, 0.3);
            `}
          >
            <SpinnerIcon
              css={css`
                width: 30px;
                overflow: visible !important;
                animation: ${keyframes`
                    from {transform:rotate(0deg);}
                    to {transform:rotate(360deg);}
                `} 2s infinite linear;

                & .fa-primary {
                  transform-origin: center;
                  animation: ${keyframes`
                    from {transform: scale(1);}
                    to { transform: scale(1.2);}
                `} 0.8s infinite alternate,
                    ${keyframes`
                    from {opacity: 1;}
                    to { opacity: 0.6;}
                `} 0.7s 0.1s infinite alternate;
                }

                & .fa-secondary {
                  transform-origin: center;
                  animation: ${keyframes`
                    from {transform: scale(1);}
                    to { transform: scale(1.2);}
                `} 1s 0.5s infinite alternate,
                    ${keyframes`
                    from {transform: translateX(0px);}
                    to { transform: translateX(20px);}
                `} 0.6s infinite alternate;
                }
              `}
            />
          </div>
        )}
      </Form.Field>
      <div
        css={css`
          flex-grow: 1;
        `}
      >
        <Form.Field>
          <label>
            Name*
            <br />
            <HandleNameError />
          </label>

          <input
            disabled={isFormDisabled}
            css={
              !!errors.name &&
              css`
                &&&&&& {
                  border-color: #ce9a9a;
                }
              `
            }
            name="name"
            defaultValue={initialValues.name}
            ref={register({ required: true })}
          />
        </Form.Field>
        <Form.Field>
          <label>
            Address*
            <br />
            <HandleAddressError />
          </label>
          <textarea
            disabled={isFormDisabled}
            rows="4"
            defaultValue={initialValues.address}
            css={
              !!errors.address &&
              css`
                &&&&&& {
                  border-color: #ce9a9a;
                }
              `
            }
            name="address"
            ref={register({
              required: true,
              validate: {
                parsable: value => {
                  return !!structuredAddressRef.current;
                },
                unambiguous: value => {
                  return (
                    structuredAddressRef.current &&
                    (structuredAddressRef.current.confidence === 'High' ||
                      structuredAddressRef.current.confidence === 'Medium')
                  );
                },
                precise: value => {
                  return (
                    structuredAddressRef.current &&
                    structuredAddressRef.current.bbox &&
                    getBboxArea(structuredAddressRef.current.bbox) < 0.001
                  );
                },
              },
            })}
            onChange={e => {
              setAddress(e.target.value);
            }}
          />
        </Form.Field>
        <Form.Field>
          <label>Telephone</label>
          <input
            disabled={isFormDisabled}
            defaultValue={initialValues.telephone}
            name="telephone"
            ref={register({ required: false })}
          />
        </Form.Field>
        <Form.Field>
          <label>Fax</label>
          <input
            disabled={isFormDisabled}
            defaultValue={initialValues.fax}
            name="fax"
            ref={register({ required: false })}
          />
        </Form.Field>
        <Form.Field>
          {mode === 'create' && (
            <Form.Button
              css={css`
                margin-left: 90px !important;
              `}
            >
              Create
            </Form.Button>
          )}
          {mode === 'edit' && !isFormDisabled && (
            <Button
              css={css`
                margin-left: 90px !important;
              `}
            >
              Update
            </Button>
          )}
          {mode === 'edit' && isFormDisabled && (
            <Button
              css={css`
                margin-left: 90px !important;
              `}
              onClick={e => {
                setIsFormDisabled(false);
                e.preventDefault();
              }}
            >
              Edit
            </Button>
          )}
          {mode === 'edit' && isFormDisabled && (
            <Button
              basic
              color="red"
              css={css`
                margin-left: auto !important;
              `}
              onClick={e => {
                onDelete();
              }}
            >
              Delete
            </Button>
          )}
        </Form.Field>
      </div>
    </Form>
  );
};
