import React from 'react';
import { NavLink } from 'react-router-dom';
import { css } from '@emotion/core';
import { ReactComponent as CardiologistsIcon } from '@fortawesome/fontawesome-pro/svgs/duotone/heartbeat.svg';
import { ReactComponent as UsersIcon } from '@fortawesome/fontawesome-pro/svgs/duotone/users.svg';
import { ReactComponent as LogoutIcon } from '@fortawesome/fontawesome-pro/svgs/duotone/sign-out.svg';
import { ReactComponent as BackIcon } from '@fortawesome/fontawesome-pro/svgs/duotone/chevron-left.svg';
import { ReactComponent as Logo } from '../../assets/logo-outline.svg';

import { darken } from 'polished';

export const Sidebar = () => {
  return (
    <div
      className="sidebar"
      css={css`
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        border-right: 1px solid #f0f0f0;
        color: #777;
        min-width: 200px;
        box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.1);
        z-index: 0;
      `}
    >
      <NavLink to="/">
        <Logo
          as={Logo}
          css={css`
            margin-top: 22px;
            margin-left: 20px;
            margin-right: 20px;
            fill: #dd5575;
            margin-bottom: 20px;
          `}
        />
      </NavLink>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex-grow: 1;

          padding-bottom: 20px;

          & .nav-item {
            font-weight: 600;
            color: #777;
            overflow: hidden;

            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;

            padding-left: 26px;
            padding-right: 26px;
            padding-top: 16px;
            padding-bottom: 16px;

            transition: 0.2s all;

            & svg {
              width: 18px;
              height: 18px;
              margin-right: 6px;

              & .fa-primary,
              & .fa-secondary {
                fill: #888;
              }
            }

            &:hover {
              background-color: hsla(206, 100%, 97%, 0.9);
              &:not(.logout)&:before {
                display: block;
                content: '';
                position: absolute;
                top: calc(50% - 5px);
                right: -5px;
                width: 10px;
                height: 10px;
                background-color: hsla(206, 100%, 42%, 1);
                border-radius: 50%;
              }
            }

            &.active {
              background-color: hsla(206, 100%, 42%, 1);
              background-image: linear-gradient(
                to left,
                hsla(206, 100%, 42%, 1) 59%,
                hsla(206, 98%, 44%, 0.9)
              );
              color: #f0f0f0;

              & svg {
                & .fa-primary,
                & .fa-secondary {
                  fill: #d3e8ff;
                }
              }
            }
          }
        `}
      >
        <NavLink className="nav-item" to="/admin/users">
          <UsersIcon />
          Users
        </NavLink>
        <NavLink className="nav-item" to="/admin/specialists">
          <CardiologistsIcon className="cardiologists" />
          Specialists
        </NavLink>

        <NavLink
          className="nav-item"
          css={css`
            margin-top: auto;
          `}
          to="/"
          exact
        >
          <BackIcon />
          Back to Site
        </NavLink>

        <div
          css={css`
            height: 1px;
            background-color: #eee;
          `}
        />

        <NavLink
          to="/logout"
          css={css`
            cursor: pointer;
            & .fa-primary {
              transition: 0.2s color,
                0.15s transform cubic-bezier(0.77, 0, 0.175, 1);
            }
            & .fa-secondary {
              transition: 0.2s all;
            }
            &:hover {
              & .fa-primary {
                fill: ${darken(0.1, '#d3e8ff')} !important;
                transform: translateX(100px);
              }
              & .fa-secondary {
                fill: ${darken(0.05, '#d3e8ff')} !important;
              }
            }
          `}
          className="nav-item logout"
        >
          Logout
          <LogoutIcon
            css={css`
              margin-left: auto;
              overflow: visible !important;
            `}
          />
        </NavLink>
      </div>
    </div>
  );
};
