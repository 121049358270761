import _ from 'lodash';
import React, { useState } from 'react';
import { css } from '@emotion/core';
import { useCardiologists } from '../../utils/useCardiologists';
import { CardiologistForm } from './CardiologistForm';
import { useCardiologistActions } from '../../utils';
import { Dropdown } from 'semantic-ui-react';
import { provinceNames } from '~/ClinicSelection';
import BarLoader from 'react-spinners/BarLoader';

export const Cardiologists = () => {
  const {
    createCardiologist,
    deleteCardiologist,
    updateCardiologist,
  } = useCardiologistActions();
  const { cardiologists, isLoading } = useCardiologists();

  const [selectedProvinceCode, setSelectedProvinceCode] = useState('_all_');

  const availableProvinceCodes = _.uniq(
    (cardiologists || []).map(x => x.structuredAddress.adminDistrict),
  );

  if (isLoading) {
    return (
      <div
        css={css`
          display: flex;
          height: 100%;
          flex-direction: column;
        `}
      >
        <div
          css={css`
            align-self: center;
            margin-top: auto;
            margin-bottom: auto;
          `}
        >
          <BarLoader color="#dddddd" />
        </div>
      </div>
    );
  }
  const sortedCardiologists = _.orderBy(
    cardiologists,
    x => {
      return x.createdAt.seconds;
    },
    'desc',
  );

  return (
    <ul
      css={css`
        list-style-type: none;
        padding: 0;
        margin: 0;

        h2 {
          margin-top: 1em;
          margin-left: 2em;
        }
      `}
    >
      <li>
        <h2>Add Specialist</h2>
        <div
          css={css`
            display: flex;
            flex-direction: row;
          `}
        >
          <CardiologistForm
            css={css`
              width: 100%;
              margin-top: 20px;
              margin-left: 20px;
              margin-right: 20px;
              padding: 20px;
            `}
            onSubmit={data => createCardiologist(data)}
            mode="create"
          />
        </div>
      </li>
      <li>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
          `}
        >
          <h2>Specialists</h2>
          <Dropdown
            css={css`
              margin-left: auto;
              margin-top: 20px;
              margin-right: 20px;
            `}
            selection
            onChange={(e, data) => setSelectedProvinceCode(data.value)}
            defaultValue="_all_"
            options={[
              {
                key: '_all_',
                value: '_all_',
                text: 'All Provinces',
              },
            ].concat(
              availableProvinceCodes.map(x => ({
                value: x,
                text: provinceNames[x],
                key: x,
              })),
            )}
          />
        </div>
      </li>
      {(selectedProvinceCode === '_all_'
        ? sortedCardiologists
        : sortedCardiologists.filter(
            x => x.structuredAddress.adminDistrict === selectedProvinceCode,
          )
      ).map(doctor => (
        <li key={doctor.id}>
          <div
            css={css`
              display: flex;
              flex-direction: row;
            `}
          >
            <CardiologistForm
              css={css`
                width: 100%;
                margin-top: 20px;
                margin-left: 20px;
                margin-right: 20px;
                padding: 20px;
              `}
              initialValues={doctor}
              submitText="Update"
              onSubmit={data => updateCardiologist(doctor.id, data)}
              onDelete={() => deleteCardiologist(doctor.id)}
              mode="edit"
            />
          </div>
        </li>
      ))}
    </ul>
  );
};
