import { storage } from 'firebase/app';
import { useCallback, useMemo, useReducer } from 'react';
const MAX_FILE_SIZE_MB = 1;
const FILETYPE_WHITELIST = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
];
export const useFileUpload = () => {
  const projectRootStorageRef = useMemo(
    () => storage().ref(`cardiouroconnect/`),
    [],
  );
  const [uploadStatusMap, dispatchUploadStatusUpdate] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'progress':
          return Object.assign({}, state, {
            [action.data.relativePath]: {
              progress: action.data.progress,
              state: storage.TaskState.RUNNING,
            },
          });
        case 'error':
          return Object.assign({}, state, {
            [action.data.relativePath]: {
              state: storage.TaskState.ERROR,
            },
          });
        case 'complete':
          return {
            [action.data.relativePath]: {
              state: storage.TaskState.SUCCESS,
              downloadUrl: action.data.downloadUrl,
            },
          };
        default:
          throw new Error();
      }
    },
    {},
  );
  const uploadFile = useCallback(
    async (file, relativePath) =>
      // promise to return the uploaded file's download-url
      new Promise((resolve, reject) => {
        if (file.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
          console.warn(
            `File size exceeds limit of ${MAX_FILE_SIZE_MB}, aborting upload.`,
          );
          return;
        }
        if (!FILETYPE_WHITELIST.includes(file.type)) {
          console.warn(
            `File type of "${
              file.type
            }" cannot be uploaded. File type must be one of (${FILETYPE_WHITELIST.join(
              ', ',
            )})`,
          );
          return;
        }
        const uploadTask = projectRootStorageRef
          .child(relativePath.replace(/^\.\//, ''))
          .put(file);
        uploadTask.on(
          'state_changed',
          function(snapshot) {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            dispatchUploadStatusUpdate({
              type: 'progress',
              data: { relativePath, progress },
            });
            // console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case storage.TaskState.PAUSED:
                console.log('Upload is paused');
                break;
              case storage.TaskState.RUNNING:
                console.log('Upload is running');
                break;
              default:
            }
          },
          function(error) {
            // Handle unsuccessful uploads
            dispatchUploadStatusUpdate({
              type: 'error',
              data: {
                relativePath,
              },
            });
            reject(error);
            console.warn(`Error uploading file.`, error);
          },
          async function() {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function(downloadUrl) {
                dispatchUploadStatusUpdate({
                  type: 'complete',
                  data: {
                    relativePath,
                    downloadUrl,
                  },
                });
                console.log('relativePath', relativePath);
                resolve(downloadUrl);
                console.log('File available at', downloadUrl);
              });
          },
        );
      }),
    [projectRootStorageRef],
  );

  return [uploadFile, uploadStatusMap];
};
