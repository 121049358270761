import { useState } from 'react';
import createContainer from 'constate';

const useInteractionState = () => {
  const [businessIdInFocus, setBusinessIdInFocus] = useState(null);
  const [selectedBusinessId, setSelectedBusinessId] = useState(null);
  const [focusTriggerSource, setFocusTriggerSource] = useState(null);
  const [businesses, setBusinesses] = useState(null);

  return {
    businesses,
    setBusinesses,
    businessIdInFocus,
    focusTriggerSource,
    focusOnBusinessId: (businessId, triggerSource) => {
      setBusinessIdInFocus(businessId);
      setFocusTriggerSource(triggerSource);
    },

    selectedBusinessId,
    setSelectedBusinessId,
  };
};

const InteractionStateContainer = createContainer(useInteractionState);

export default InteractionStateContainer;
