import React from 'react';
import { css } from '@emotion/core';
import { useLanguage } from '~/utils';

export const Banner = ({ children, ...props }) => {
  const { enFr, setLanguage } = useLanguage();

  return (
    <div
      css={css`
        height: 100px;
        position: relative;
        background-color: #8a2737;
        background-image: url(${require('../assets/banner-bg.png')}),
          url(${require('../assets/banner-bg-repeat.png')});
        background-size: auto 100%;
        background-repeat: no-repeat, repeat-x;
        @media screen and (max-width: 600px) {
        }
      `}
      {...props}
    >
      <div
        css={css`
          margin-left: auto;
          margin-right: auto;
          max-width: 1000px;

          position: absolute;
          left: 0;
          right: 0;
          top: 10px;
          margin: auto;
          text-align: right;
          padding-right: 20px;
          /* right: 10px; */
          font-size: 12px;
          opacity: 0.9;

          @media screen and (max-width: 600px) {
            position: relative;
            padding-bottom: 10px;
            padding-top: 10px;
          }
        `}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          css={enFr(
            css`
              font-weight: bold;
              color: #ffffff;
              &:hover {
                color: #ffffff;
                text-decoration: underline;
              }
            `,
            css`
              cursor: pointer;
              color: #ffffff;
              &:hover {
                color: #ffffff;
              }
            `,
          )}
          onClick={() => setLanguage('en')}
        >
          English
        </a>{' '}
        <span
          css={css`
            color: #ffffff;
          `}
        >
          |
        </span>{' '}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          css={enFr(
            css`
              cursor: pointer;
              color: #ffffff;
              &:hover {
                color: #ffffff;
                text-decoration: underline;
              }
            `,
            css`
              font-weight: bold;
              color: #ffffff;
              &:hover {
                color: #ffffff;
              }
            `,
          )}
          onClick={() => setLanguage('fr')}
        >
          French
        </a>
      </div>
      {children}
    </div>
  );
};
