import _ from 'lodash';
import jsonp from 'jsonp';
import { useState, useEffect } from 'react';

const BING_API_KEY = process.env.REACT_APP_BING_MAP_KEY;
/*
example of structured address:
{
  "addressLine": "89 Norman St",
  "adminDistrict": "ON",
  "adminDistrict2": "Lambton",
  "countryRegion": "Canada",
  "formattedAddress": "89 Norman St, Sarnia, ON N7T 6S3, Canada",
  "locality": "Sarnia",
  "postalCode": "N7T 6S3"
}

city: locality
province: adminDistrict

*/
export const useLocationByQuery = (query, { noop }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [coordinates, setCoordinates] = useState(null);
  const [structuredAddress, setStructuredAddress] = useState(null);

  useEffect(() => {
    if (!query || noop) {
      // no query
      return;
    }
    setIsLoading(false);
    const cancel = jsonp(
      `https://dev.virtualearth.net/REST/v1/Locations?maxResults=1&key=${BING_API_KEY}&query=${encodeURIComponent(
        query,
      )}`,

      {
        param: 'jsonp',
      },
      (err, data) => {
        if (err) {
          console.error(err);
        }
        setCoordinates(
          _.get(data, 'resourceSets[0].resources[0].point.coordinates'),
        );
        setStructuredAddress(_.get(data, 'resourceSets[0].resources[0]'));
        setIsLoading(false);
      },
    );

    return () => {
      cancel();
    };
  }, [query, noop]);

  return { structuredAddress, coordinates, isLoading };
};
