import React, { useEffect, useState } from 'react';
import * as firebase from 'firebase/app';
import { hot } from 'react-hot-loader/root';
import {
  BrowserRouter as Router,
  NavLink,
  Redirect,
  Switch,
  Route,
  Link,
} from 'react-router-dom';
import BarLoader from 'react-spinners/BarLoader';
import { Login } from './Login';
import { useUserData, AuthProvider, useAuthContext } from './auth';
import { Admin } from './Admin';
import { ThankYouForRegistering } from './Login/ThankYouForRegistering';
import { ClinicSelection } from './ClinicSelection/ClinicSelection';
import { Banner } from './components';
import { Privacy } from './Privacy';
import { PageBanner } from './ClinicSelection/PageBanner';
import { css } from '@emotion/core';
import { TemplatePreview } from './TemplatePreview';
import { HandleSignin } from './HandleSignin';
import { createBrowserHistory } from 'history';
import { tracker } from './utils/tracker';

const history = createBrowserHistory();
window.ga = window.ga || (() => {});
history.listen((location, action) => {
  tracker.pageview(location.pathname, window.location.hostname).send();
});

const Logout = () => {
  const { logout } = useAuthContext();
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  useEffect(() => {
    logout().then(() => (window.location.href = '/'));
  }, [logout]);
  return isLoggedOut && <Redirect to="/" />;
};

function App() {
  const {
    basicUser,
    initialising: initialisingAuth,
    error,
    logout,
  } = useAuthContext();
  const [userFromDatabase, isLoadingUser] = useUserData(
    basicUser && basicUser.uid,
  );

  return (
    <Router history={history}>
      {/* <Route
        render={() => {
          if (!isLoadingUser && !initialisingAuth && !basicUser) {
            return <Redirect to="/login" />;
          }
        }}
      /> */}
      <Switch>
        <Route
          path="/admin"
          render={() => {
            return null;
          }}
        />
        <Route
          path="/login"
          render={() => {
            return null;
          }}
        />
        <Route
          path="/"
          exact
          render={() => {
            return null;
          }}
        />

        <Route
          render={() => {
            return (
              <>
                <div
                  css={css`
                    height: 100px;
                    @media screen and (max-width: 600px) {
                      display: none;
                    }
                  `}
                ></div>
                <PageBanner
                  key="banner"
                  css={css`
                    position: fixed;
                    @media screen and (max-width: 600px) {
                      position: relative;
                      margin-bottom: 0;
                    }
                    top: 0;
                    z-index: 20;
                    width: 100%;
                    margin-bottom: 100px;
                    &:after {
                      content: '';
                      position: absolute;
                      bottom: -20px;
                      height: 20px;
                      background-image: linear-gradient(
                        to bottom,
                        #ffffff,
                        rgba(255, 255, 255, 0)
                      );
                    }
                  `}
                />
              </>
            );
          }}
        />
      </Switch>
      <Switch>
        <Route
          path="/"
          exact
          render={() => {
            if (!isLoadingUser && !initialisingAuth && !basicUser) {
              return <Redirect to="/login" />;
            }
            if (isLoadingUser || initialisingAuth) {
              return (
                <div
                  css={css`
                    display: flex;
                    height: 100%;
                    flex-direction: column;
                  `}
                >
                  <Banner />
                  <div
                    css={css`
                      align-self: center;
                      margin-top: auto;
                      margin-bottom: auto;
                    `}
                  >
                    <BarLoader color="#dddddd" />
                  </div>
                </div>
              );
            }
            if (userFromDatabase && userFromDatabase.isApproved) {
              return <Redirect to="/clinics" />;
            }
            if (userFromDatabase && !userFromDatabase.isApproved) {
              return <ThankYouForRegistering />;
            }
          }}
        />
        <Route path="/login" component={Login} />
        <Route path="/logout" component={Logout} />
        <Route path="/clinics" component={ClinicSelection} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/handle-signin" component={HandleSignin} />
        <Route path="/test-preview" component={TemplatePreview} />
        <Route
          path="/admin"
          render={() => {
            if (initialisingAuth || isLoadingUser) {
              return (
                <div
                  css={css`
                    display: flex;
                    height: 100%;
                    flex-direction: column;
                  `}
                >
                  <div
                    css={css`
                      align-self: center;
                      margin-top: auto;
                      margin-bottom: auto;
                    `}
                  >
                    <BarLoader color="#dddddd" />
                  </div>
                </div>
              );
            }
            if (!userFromDatabase || !userFromDatabase.isAdmin) {
              return 'not admin';
              console.warn('Not admin, redirecting...');
              // return <Redirect to="/" />;
            }
            return <Admin />;
          }}
        />
        <Route
          render={() => (
            <div
              css={css`
                display: flex;
                height: calc(100% - 105px);
                flex-direction: column;
              `}
            >
              <div
                css={css`
                  align-self: center;
                  margin-top: auto;
                  margin-bottom: auto;
                `}
              >
                <h2>Page not found</h2>
                We couldn't find the page you're trying to reach. Try{' '}
                <NavLink to="/">going home</NavLink>.
              </div>
            </div>
          )}
        />
      </Switch>
      <Switch>
        <Route path="/admin" />
      </Switch>
    </Router>
  );
}

const Providers = () => {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
};

export default hot(Providers);
