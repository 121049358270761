import { useCallback } from 'react';
import * as firebase from 'firebase/app';

const db = firebase.firestore();

export const useUserActions = () => {
  const users = useCallback(db.collection('users'), []);

  // const createUser = useCallback(
  //   async ({ email, password, ...otherFields }) => {
  //     try {
  //       await firebase.auth().createUserWithEmailAndPassword(email, password);
  //       users.add({
  //         ...otherFields,
  //         approved: true,
  //         isAdmin: false,
  //         createdAt: new Date(),
  //       });
  //     } catch (e) {
  //       console.warn(`Could not create user. ${e}`);
  //       throw e;
  //     }
  //   },
  //   [users],
  // );

  const handleUserLogin = useCallback(
    id => users.doc(id).update({ lastLoggedIn: new Date() }),
    [users],
  );

  const handleUserRegistration = useCallback(
    (id, { email }) =>
      users.doc(id).set({
        email,
        isApproved: false,
        isAdmin: false,
        createdAt: new Date(),
        lastLoggedIn: new Date(),
      }),
    [users],
  );

  const updateUser = useCallback(
    (id, fields) => {
      return users.doc(id).update(fields);
    },
    [users],
  );

  const deleteUser = useCallback(
    id => {
      return users.doc(id).delete();
    },
    [users],
  );

  return {
    handleUserLogin,
    handleUserRegistration,
    updateUser,
    deleteUser,
  };
};
