import React, { useState, useRef } from 'react';
import * as firebase from 'firebase/app';
// import ReCAPTCHA from 'react-google-recaptcha';
import { css } from '@emotion/core';
import { Input, Button } from 'semantic-ui-react';
import { Button as RedButton, Separator } from '../components';
import { useAuthContext } from '../auth';
import { Redirect } from 'react-router';
import { useUserActions } from '~/Admin/Users/userHooks';
import { tracker } from '~/utils/tracker';
import { useLanguage } from '~/utils';

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be whitelisted in the Firebase Console.
  url: `${window.location.protocol}//${window.location.host}/handle-signin`,
  // This must be true.
  handleCodeInApp: true,
  // dynamicLinkDomain: window.location.hostname,
};

const EmailSignInCTA = ({ email }) => {
  const [isSent, setIsSent] = useState(false);
  if (isSent) {
    return <span>A sign in link has been sent to {email}</span>;
  }
  return (
    <>
      Forgot your password?
      <span
        css={css`
          cursor: pointer;
          color: #4183c4;
        `}
        onClick={() => {
          firebase
            .auth()
            .sendSignInLinkToEmail(email, actionCodeSettings)
            .then(function() {
              setIsSent(true);
              // The link was successfully sent. Inform the user.
              // Save the email locally so you don't need to ask the user for it again
              // if they open the link on the same device.
              console.log('setting email for signin');
              window.localStorage.setItem('emailForSignIn', email);
            });
        }}
      >
        Receive a sign-in link via email
      </span>
    </>
  );
};

export const LoginForm = props => {
  const { enFr } = useLanguage();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loginError, setLoginError] = useState();
  const [signupError, setSignupError] = useState();
  const { basicUser, login, signup } = useAuthContext();
  const recaptchaRef = useRef();
  const { handleUserLogin, handleUserRegistration } = useUserActions();
  const [isLoginMode, setIsLoginMode] = useState(true);

  // useEffect(() => {
  //   recaptchaRef.current.execute();
  // }, []);

  const handleLogin = () => {
    setSignupError(null);
    login(email, password)
      .then(({ user }) => {
        tracker.set('uid', user.uid);
        handleUserLogin(user.uid);
      })
      .catch(error => setLoginError(error));
  };

  const handleSignup = () => {
    setLoginError(null);
    signup(email, password)
      .then(({ user }) => {
        handleUserRegistration(user.uid, { email });
      })
      .catch(error => setSignupError(error));
  };

  if (basicUser) {
    return <Redirect to="/" />;
  }

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 3px;
        background-color: #e6e3df;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04);
        margin-top: 40px;
        width: 600px;
        max-width: 100%;
      `}
    >
      <form
        key={isLoginMode ? 'login' : 'register'}
        onSubmit={e => {
          handleLogin();
          e.preventDefault();
        }}
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 300px;
          max-width: 100%;
          margin-top: 30px;
          margin-bottom: 30px;
          & .cuc-input {
            width: 100%;
          }
        `}
      >
        <Input
          id="email"
          className="cuc-input"
          css={css``}
          placeholder={
            isLoginMode
              ? enFr('Login Email', 'Courriel de connexion')
              : enFr(
                  'Enter your email address',
                  'Entrez votre adresse de courriel',
                )
          }
          onChange={e => setEmail(e.target.value)}
        />

        <Input
          id="password"
          className="cuc-input"
          css={css`
            margin-top: 12px;
          `}
          placeholder={
            isLoginMode
              ? enFr('Password', 'Mot de passe')
              : enFr('Select your password', 'Sélectionnez votre mot de passe')
          }
          type="password"
          onChange={e => setPassword(e.target.value)}
        />

        <Separator
          css={css`
            margin-top: 20px;
            margin-bottom: 20px;
            width: 30%;
          `}
        />

        {isLoginMode ? (
          <>
            <RedButton
              css={css`
                width: 100%;
                &[disabled] {
                  background-color: #999;
                }
              `}
            >
              {enFr('Login', 'Ouvrir une session')}
            </RedButton>
            {loginError && (
              <>
                <p
                  css={css`
                    color: #522;
                    margin-top: 20px;
                  `}
                >
                  {loginError.message}
                </p>
                <EmailSignInCTA email={email} />
              </>
            )}
          </>
        ) : (
          <>
            <RedButton
              type="button"
              onClick={e => {
                handleSignup();
                e.preventDefault();
              }}
              css={css`
                width: 100%;
                &[disabled] {
                  background-color: #999;
                }
              `}
            >
              {enFr('Register', 'S’inscrire')}
            </RedButton>

            {signupError && (
              <p
                css={css`
                  color: #522;
                  margin-top: 20px;
                `}
              >
                {signupError.message}
              </p>
            )}
          </>
        )}

        {isLoginMode ? (
          <a
            href="#"
            css={css`
              display: block;
              margin-top: 20px !important;
            `}
            onClick={() => setIsLoginMode(false)}
          >
            {enFr('or register a new account', 'ou s’inscrire')}
          </a>
        ) : (
          <a
            href="#"
            css={css`
              display: block;
              margin-top: 20px !important;
            `}
            onClick={() => setIsLoginMode(true)}
          >
            {enFr(
              'or login with existing account',
              'ou ouvrir une session sur un compte existant',
            )}
          </a>
        )}

        {/* <ReCAPTCHA
          ref={recaptchaRef}
          onChange={e => {
            console.log(e);
          }}
          // sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY_V2_CHECKBOX}
          size="invisible"
          sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY_V2_INVISIBLE}
        /> */}
      </form>
    </div>
  );
};
