import React from 'react';
import css from '@emotion/css';
import _ from 'lodash';
import { generatePdf, useLanguage } from '../utils';

const _cardioInfo = `Cardio-Diagnostics
Met Hospital
1995 Lens Ave
Windsor, ON N8W 1L9
t: 519-254-5577 ext. 52385 f:
`;
const _uroInfo = `John Smith
Hospital
123 Main St
Toronto, ON M5V 2V5
t: 123-456-7890 ext. 123 f:
`;

export const TemplatePreview = React.forwardRef(
  ({ cardioInfo, uroInfo, ...props }, ref) => {
    const { enFr } = useLanguage();
    // const wrapperEl = React.createRef();
    const now = new Date();

    const date = `${now.getFullYear()}/${_.padStart(
      now.getMonth(),
      2,
      '0',
    )}/${_.padStart(now.getDate(), 2, '0')}`;

    const scale = 1.5;

    return (
      <div
        ref={ref}
        css={css`
          width: ${595.28 * scale}px;
          height: ${841.89 * scale}px;
          position: relative;
        `}
        // onClick={handleClick}
        {...props}
      >
        <img
          css={css`
            width: 100%;
          `}
          src={enFr(
            require('./base-template.png'),
            require('./base-template-fr.png'),
          )}
          alt=""
        />
        <p
          css={css`
            position: absolute;

            width: ${260 * scale}px;
            top: ${135 * scale}px;
            left: ${35 * scale}px;
            font-size: ${9 * scale}px;
          `}
        >
          {date}
        </p>

        <p
          css={css`
            position: absolute;
            width: ${260 * scale}px;
            top: ${195 * scale}px;
            left: ${35 * scale}px;
            font-size: ${9 * scale}px;

            white-space: pre-line;
          `}
        >
          {cardioInfo || _cardioInfo}
        </p>

        <p
          css={css`
            position: absolute;
            white-space: pre-line;

            width: ${260 * scale}px;
            left: ${297 * scale}px;
            top: ${195 * scale}px;
            font-size: ${9 * scale}px;
          `}
        >
          {uroInfo || _uroInfo}
        </p>
      </div>
    );
  },
);
