import { useCallback } from 'react';
import * as firebase from 'firebase/app';

const db = firebase.firestore();

export const useCardiologistActions = () => {
  const cardiologists = useCallback(db.collection('cardiologists'), []);

  const createCardiologist = useCallback(
    fields => {
      const addAction = cardiologists.add({ ...fields, createdAt: new Date() });
      return addAction;
    },
    [cardiologists],
  );

  const deleteCardiologist = useCallback(
    id => {
      return cardiologists.doc(id).delete();
    },
    [cardiologists],
  );

  const updateCardiologist = useCallback(
    (id, fields) => {
      return cardiologists.doc(id).update(fields);
    },
    [cardiologists],
  );

  return {
    createCardiologist,
    deleteCardiologist,
    updateCardiologist,
  };
};
