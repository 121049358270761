import React from 'react';
import { PageBanner } from '~/ClinicSelection/PageBanner';
import { css } from '@emotion/core';
import { useLanguage } from '~/utils';
import { Footer } from '~/components';

export const Privacy = () => {
  const { enFr } = useLanguage();

  return (
    <div>
      <div
        css={css`
          margin-left: auto;
          margin-right: auto;
          width: 100%;
          max-width: 800px;
          padding-left: 20px;
          padding-right: 20px;
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          position: relative;
          z-index: 11;
          padding-bottom: 100px;

          p {
            position: relative;
            display: block;
          }
          .anchor[id] {
            position: relative;
            position: absolute;
            top: -120px;
            width: 100%;
            height: 100%;
            pointer-events: none;
            &:target:after {
              content: '';
              display: block;
              position: absolute;
              top: 115px;
              left: -10px;
              right: -10px;
              bottom: -125px;
              border-radius: 3px;
              box-shadow: 0 0 3px 2px rgba(171, 229, 255, 0.3);
            }
          }
        `}
      >
        <p>
          {enFr('Thank you for visiting', 'Merci de votre visite sur')}{' '}
          <a href="https://www.CardioUroConnect.ca">www.CardioUroConnect.ca</a>.
        </p>
        <p>
          {enFr(
            `At Ferring, your privacy is important to us and we are committed to ensuring our data protection practices adhere to applicable Canadian and European Economic Area (EEA) laws.`,
            `À Ferring, la protection de vos renseignements personnels est importante pour nous. Nous nous engageons à nous assurer que nos pratiques de protection des données sont conformes aux lois canadiennes et de l’Espace économique européen (EEE). `,
          )}
        </p>

        <p>
          {enFr(
            `This Privacy Notice will provide information as to how Ferring may collect, hold and process your personal data, and will describe the ways in which we will ensure your personal data is protected in accordance with the applicable regulations.`,
            `Le présent énoncé de confidentialité donne des renseignements sur la manière dont Ferring peut recueillir, conserver et traiter vos données personnelles et il décrit les façons dont nous nous assurerons que vos données personnelles sont protégées conformément à la réglementation applicable.`,
          )}
        </p>
        <p>
          {enFr(
            `This Privacy Notice applies only to this website, therefore if you visit another website related to or recommended by Ferring, please consult the privacy notice on the respective website.`,
            `Le présent énoncé de confidentialité s’applique seulement à ce site Web. Par conséquent, si vous consultez un autre site Web lié à Ferring ou que celle-ci a recommandé, consultez la politique de confidentialité du site Web respectif.`,
          )}
          <ul>
            <li>
              <a href="#collect">
                {enFr(
                  `How Ferring may collect your personal data`,
                  `Recueil de vos données personnelles par Ferring`,
                )}
              </a>
            </li>
            <li>
              <a href="#use">
                {enFr(
                  `How Ferring may use your personal data`,
                  `Utilisation de vos données personnelles par Ferring`,
                )}
              </a>
            </li>
            <li>
              <a href="#cookies">{enFr(`Cookies`, `Témoins`)}</a>
            </li>
            <li>
              <a href="#retention">
                {enFr(
                  `Retention of personal data`,
                  `Conservation des données personnelles`,
                )}
              </a>
            </li>
            <li>
              <a href="#rights">{enFr(`Your rights`, `Vos droits`)}</a>
            </li>
            <li>
              <a href="#share">
                {enFr(
                  `How Ferring may share or transfer your personal data`,
                  `Partage ou transfert de vos données personnelles par Ferring`,
                )}
              </a>
            </li>
            {/* <li>
            <a href="#links">Links to other websites</a>
          </li> */}
            <li>
              <a href="#security">
                {enFr(`Security information`, `Renseignements sur la sécurité`)}
              </a>
            </li>
            {/* <li>
            <a href="#children">Children</a>
          </li> */}
            <li>
              <a href="#modifications">
                {enFr(`Modifications`, `Modifications`)}
              </a>
            </li>
            <li>
              <a href="#contact">
                {enFr(`How to contact us`, `Pour communiquer avec nous`)}
              </a>
            </li>
          </ul>
        </p>

        <p>
          <div id="collect" className="anchor" />
          <b>
            {enFr(
              `How Ferring may collect your personal data`,
              `Recueil de vos données personnelles par Ferring`,
            )}
          </b>
          <br />
          {enFr(
            `Personal data means all information that relates to an identified or
            identifiable individual. This includes name, postal address, phone
            number, email address or any other information which may be used to
            identify you personally. If you are not already a data subject of
            Ferring, there may be opportunities during your visit to this website
            whereby you provide personal data voluntarily, and therefore become a
            data subject. Ferring will only collect personal data from you during
            your visit to this website if and when you voluntarily provide it.`,
            `Les données personnelles désignent tous les renseignements qui se rapportent à une personne identifiée ou identifiable. Cela comprend le nom, l’adresse postale, le numéro de téléphone, l’adresse électronique ou tout autre renseignement qui peut être utilisé pour vous identifier personnellement. Si vous n’êtes pas déjà une personne concernée de Ferring, il se peut que vous fournissiez volontairement des données personnelles pendant votre visite de ce site Web et que vous deveniez par conséquent une personne concernée. Ferring recueille vos données personnelles lors de votre visite sur ce site Web seulement si et lorsque vous les fournissez volontairement.`,
          )}
        </p>

        {/* eslint-disable prettier/prettier */}
        <p>
          <div id="use" className="anchor" />
          <b>
            {enFr(
              `How Ferring may use your personal data`,
              `Utilisation de vos données personnelles par Ferring`,
            )}
          </b>
          <br />
          {enFr(
            `Ferring will only process your personal data where there is a legal
            basis for doing so, and where it is necessary to carry out the purpose
            for which it was collected. The legal basis for processing your
            personal data will depend on the purpose for which the data was
            collected, as described below:`,
            `Ferring traite vos données personnelles seulement lorsqu’il existe un fondement juridique pour le faire, et lorsque cela est nécessaire pour réaliser l’objectif pour lequel elles ont été recueillies. Le fondement juridique pour le traitement de vos données personnelles dépend de la raison pour laquelle les données ont été recueillies, comme décrit\u00a0ci\u2011dessous\u00A0:`,
          )}

          <ul css={css`& ul { list-style-type: disc; }`}>
            <li>
              {enFr(
                `Personal data may be processed if necessary for our legitimate
              interests in pursuing or fulfilling the following purposes: `,
                `Les données personnelles peuvent être traitées si nécessaire pour nos intérêts légitimes dans la poursuite ou l’exécution des objectifs suivants : `,
              )}
              <ul>
                <li>
                {enFr(
                  `to respond to you regarding an inquiry in the following
                  categories: media; partnerships; development of business
                  relationship; and any other general enquiries;`,
                  `pour répondre à une demande de renseignements vous concernant dans les catégories suivantes : médias, partenariats, développement de relations d’affaires, toute autre demande générale;`,
                )}
              </li>
              <li>
                {enFr(
                  `to process and respond to enquiries regarding data privacy and
                  your privacy rights;`,
                  `traiter les demandes concernant la confidentialité des données et vos droits de protection des renseignements personnels et y répondre;`,
                )}
              </li>
              <li>
                {enFr(
                  `to process and assess reported cases for potential misconduct
                  and/or violation of the Ferring Philosophy;`,
                  `traiter et évaluer les cas signalés d’inconduite potentielle ou de violation de la philosophie de Ferring;`,
                )}
              </li>
              <li>
                {enFr(
                  `to process applications for current vacancies and/or accept
                  unsolicited job applications;`,
                  `traiter les candidatures aux postes actuellement vacants ou accepter les demandes d’emploi non sollicitées;`,
                )}
              </li>
              <li>
                {enFr(
                  `to process and respond to a collaboration request; or`,
                  `traiter les demandes de collaboration et y répondre;`,
                )}
              </li>
              <li>
              {enFr(
                `any other legitimate business purposes such as data analysis,
                audit, improving services and expanding our business activities.`,
                `pour tout autre objectif commercial légitime, comme l’analyse des données, l’audit, l’amélioration des services et l’expansion de nos activités commerciales.`,
              )}
            </li>
              </ul>
            </li>
            
            <li>
              {enFr(
                `Personal data may be processed when necessary to comply with
              applicable laws and regulations such as: `,
                `Les données personnelles peuvent être traitées lorsque c’est nécessaire pour se conformer aux lois et aux règlements, par exemple :`,
              )}
              <ul>
              <li>
              {enFr(
                `to comply with laws within or outside your country of residence,`,
                `pour se conformer aux lois de votre pays de résidence ou d’autres pays,`,
              )}
            </li>
            <li>
              {enFr(
                `to comply with a legal process or in response to a court order to
              produce certain personal data.`,
                `pour se conformer à un processus judiciaire ou en réponse à une ordonnance du tribunal de produire certaines données personnelles.`,
              )}
            </li>
                </ul>
            </li>
            
            <li>
              {enFr(
                `Personal data may be collected and processed if necessary for
              vital interests and to comply with applicable safety obligations
              within the pharmaceutical sector such as:`,
                `Des données personnelles peuvent être recueillies et traitées si cela est nécessaire pour les intérêts vitaux et pour se conformer aux obligations en matière de sécurité applicables dans le secteur pharmaceutique, notamment :`,
              )}
              <ul>
              <li>
              {enFr(
                `to process a report regarding side effects, adverse drug
              reactions, adverse events or other safety information relation to
              a Ferring drug/product. For information regarding the processing
              of special category data for this purpose please see the `,
                `pour traiter un rapport relatif aux effets secondaires, aux effets indésirables, aux événements indésirables ou d’autres renseignements sur l’innocuité concernant un médicament ou un produit Ferring. Pour en savoir plus au sujet du traitement des données des catégories spéciales à cette fin, consultez l’`,
              )}
              <a
                href="https://www.ferring.com/media/2761/pharmacovigilance-privacy-notice_final.pdf"
                target="_blank"
              >
                {enFr(
                  `Pharmacovigilance Privacy Notice`,
                  `énoncé de confidentialité de pharmacovigilance`,
                )}
              </a>
              {enFr('', '(en anglais)')}.
            </li>
                </ul>
            </li>
            
          </ul>
          {enFr(
            `Your personal data will only be used for the specific purpose for
              which is was collected, and will not be used for any other purpose
              without notifying you in advance and/or obtaining the appropriate
              consent.`,
            `Vos données personnelles seront utilisées seulement aux fins pour lesquelles elles ont été collectées et elles ne seront pas utilisées à d’autres fins sans que vous en soyez avisé au préalable ou en l’absence du consentement approprié.`,
          )}
        </p>

        <p>
          <div id="cookies" className="anchor" />
          <b>{enFr(`Cookies`, `Témoins`)}</b>
          <br />
          {enFr(
            `When you visit this website, data about your use of the website may be
              collected using Google Analytics. This tracking technology uses
              cookies to collect aggregate data about website usage and activity,
              such as how many users visit the website and how often. This data may
              be shared with a third party service provider in order to further
              improve our web services. No personal data can be accessed or
              collected via these cookies. The use of this type of technology shall
              comply with applicable data protection regulations and the current
              E-Privacy Regulation.`,
            `Lorsque vous visitez le présent site Web, les données concernant votre utilisation du site Web peuvent être recueillies à l’aide de Google Analytics. Cette technologie de suivi utilise des témoins pour collecter des données agrégées sur l’utilisation du site Web et l’activité sur celui-ci, comme le nombre d’utilisateurs qui visitent le site Web et à quelle fréquence. Ces données peuvent être partagées avec un fournisseur de service tiers afin d’améliorer nos services Web. Aucune donnée personnelle ne peut être consultée ou recueillie par l’intermédiaire de ces témoins. L’utilisation de ce type de technologie est conforme à la réglementation applicable sur la protection des données et à la réglementation actuelle sur le respect de la vie privée dans les communications électroniques.`,
          )}
        </p>

        <p>
          <div id="retention" className="anchor" />
          <b>
            {enFr(
              `Retention of personal data`,
              `Conservation des données personnelles`
            )}
            
          </b>
          <br />
          {enFr(
              `Ferring will follow the appropriate actions to ensure your personal
              data is retained only for the period required to fulfil the purposes
              outlined in this Privacy Notice. Ferring will only retain personal
              data for a longer period if required or permitted by law or by
              applicable regulations within the pharmaceutical industry.`,
              `Ferring prend les mesures appropriées pour s’assurer que vos données personnelles sont conservées uniquement pendant la période nécessaire pour atteindre l’objectif décrit dans le présent énoncé de confidentialité. Ferring conserve les données personnelles pour une période plus longue si la loi ou les règlements applicables dans l’industrie pharmaceutique le requièrent ou le permettent.`
            )}
          
        </p>

        <p>
          <div id="rights" className="anchor" />
          <b>
          {enFr(
              `Your rights`,
              `Vos droits`
            )}
            </b>
          <br />
          {enFr(
              `If and when we collect, hold and process your personal data, you will
              be afforded specific rights as a data subject. Ferring will give effect to these rights in accordance with the applicable Canadian and European data protection laws.`,
              `Lorsque nous recueillons, conservons et traitons vos données personnelles, des droits particuliers vous sont conférés à titre de personne concernée. Ferring donne effet à ces droits conformément aux lois canadiennes et européennes applicables sur la protection des données.`
            )}
          <ul>
            <li>
            {enFr(
              `Right to access and rectification: You have the right to request a
              copy of the information we hold about you, as well as the right to
              request the correction of the personal data we hold if it is
              inaccurate.`,
              `Droit d’accès et de rectification : Vous avez le droit de demander un exemplaire de l’information que nous détenons sur vous, ainsi que le droit de demander la correction des renseignements personnels que nous détenons s’ils sont inexacts.`
            )}
              
            </li>
            <li>
            {enFr(
              `Right to erasure and restriction of processing: You have the right
              to request the erasure of your personal data or for the
              restriction of processing your personal data. These rights are
              limited and will apply only in certain circumstances and where no
              exemptions apply.`,
              `Droit à l’effacement et à la limitation du traitement : Vous avez le droit de demander l’effacement de vos données personnelles ou la limitation du traitement de vos données personnelles. Ces droits sont limités et s’appliquent seulement dans certaines circonstances et lorsqu’aucune exemption ne s’applique.`
            )}
              
            </li>
            <li>
            {enFr(
              `Right to data portability: You have the right to request a copy of
              the personal data we hold, as well as the right to request this
              data be transferred to another controller. This right is subject
              to certain conditions and may not apply, for example, if not
              permitted for reasons of public interest, or is not technically
              feasible.`,
              `Droit à la portabilité des données : Vous avez le droit de demander un exemplaire des renseignements personnels que nous détenons, ainsi que le droit de demander que ces données soient transférées à un autre responsable du traitement. Ce droit est soumis à certaines conditions et peut ne pas s’appliquer, par exemple, s’il n’est pas autorisé pour des raisons d’intérêt public ou s’il n’est pas techniquement possible.`
            )}
              
            </li>
            <li>
            {enFr(
              `Right to object: You have the right to object to the processing of
              your personal data. If the data is processed for reasons of public
              interest, scientific or statistical purposes, you may still object
              and it will be our responsibility to demonstrate the legitimacy of
              processing the data.`,
              `Droit de s’opposer : Vous avez le droit de vous opposer au traitement de vos données personnelles. Si les données sont traitées pour des raisons d’intérêt public, scientifiques ou à des fins statistiques, vous pouvez tout de même vous y opposer, et il nous incombera de démontrer la légitimité du traitement des données.`
            )}
              
            </li>
            <li>
            {enFr(
              `You have the right to contact or lodge a complaint with your local
              data protection supervisory authority.`,
              `Vous avez le droit de communiquer avec votre autorité de contrôle locale de protection des données ou de déposer une plainte auprès de celle-ci.`
            )}
              
            </li>
            <li>
            {enFr(
              `If you would like to submit a request in relation to a data
              subject right, please contact the Global Privacy Office via email
              at `,
              `Si vous souhaitez soumettre une demande relative au droit d’une personne concernée, communiquez avec le bureau mondial de la confidentialité par courriel à l’adresse `
            )}

              <a href="mailto:privacy@ferring.com" target="_blank">
                privacy@ferring.com
              </a>.{' '}{enFr(
              `We will respond to a request within 30 days.`,
              `Nous répondons aux demandes dans les 30 jours.`
            )}
            
            </li>
          </ul>
        </p>

        <p>
          <div id="share" className="anchor" />
          <b>
          {enFr(
            `How Ferring may transfer or share your personal data`,
            `Partage ou transfert de vos données personnelles par Ferring`
          )}
          </b>
          <br />
          {enFr(
            `Your personal data may be transferred to other locations in which Ferring maintains facilities (including Ferring affiliates, contractors, partners or agents), where there are legitimate grounds for such a transfer, such as a valid business reason. Such locations may include countries outside the European Economic Area (EEA) including Canada.
            Any
            such transfers will be safeguarded by internal international data
            transfer agreements, to ensure that your personal data is protected in
            accordance with the applicable European and national data protection
            laws. Ferring may, in certain circumstances, share your personal data
            with third parties if necessary to achieve the notified purposes, or
            if such third parties are assisting Ferring with the processing of the
            data. Ferring will only disclose personal data about you to a third
            party in the following circumstances:`,
            <>
            <p>
            Vos données personnelles peuvent être transférées à d’autres emplacements où Ferring possède des installations de conservation (y compris des sociétés affiliées, des entrepreneurs, des partenaires ou des mandataires de Ferring), s’il existe des motifs légitimes pour un tel transfert, comme une raison commerciale valable. Ces emplacements peuvent comprendre des pays extérieurs à l’EEE, y compris le Canada. 
            Ces transferts sont protégés par les accords internes sur le transfert international de données, pour vous assurer que vos données personnelles sont protégées conformément aux directives européennes et aux lois nationales sur la protection des données.
            </p>
            <p>
              Ferring peut, dans certaines circonstances, partager vos renseignements personnels avec des tiers si cela est nécessaire pour atteindre les objectifs prévus dans un avis ou lorsque ces tiers aident Ferring pour le traitement des données. Ferring divulgue des données personnelles vous concernant à un tiers seulement dans les circonstances suivantes :
            </p>
            </>
          )}
          
          <ul>
            <li>
            {enFr(
              `relevant consent has been provided by you;`,
              `Vous avez donné le consentement pertinent;`
            )}
            
          </li>
            <li>
              {enFr(
                `where applicable, as a result of a request by you as a data
                subject`,
                `Le cas échéant, à la suite d’une demande de votre part à titre de personne concernée;`
              )}
              
            </li>
            <li>
              {enFr(
                `whereby there is a legal basis for the disclosure of the data and
                you have been provided with suitable notice of this`,
                `S’il existe un fondement juridique pour la divulgation des données et si vous avez reçu un préavis approprié à cet effet;`
              )}
              
            </li>
            <li>
              {enFr(
                `where we are required to do so under applicable laws or as a
                result of a court order; or`,
                `Si nous sommes tenus de le faire en vertu des lois applicables ou à la suite de l’ordonnance d’un tribunal;`
              )}
              
            </li>
            <li>
              {enFr(
                `where Ferring decides to sell, buy, merge or otherwise reorganise
                its business structure.`,
                `Si Ferring décide de vendre, d’acheter, de fusionner ou autrement de réorganiser sa structure organisationnelle.`
              )}
              
            </li>
          </ul>
        </p>
        <p>
          <b>
          {enFr(
                `Ferring will never sell your information to a third party.`,
                `Ferring ne vendra jamais vos renseignements à un tiers.`
              )}
          </b>
        </p>

        {/* <p>
        <div id="links" className="anchor"/>
        Links to other websites
        This website may provide links to associated or external websites. Ferring is not responsible for the content, quality, data processing activities or data protection practices of any other website. If you visit another website please consult the privacy notice and privacy settings of the respective website.
        </p> */}

        <p>
          <div id="security" className="anchor" />
          <b>
            {enFr(
              `Security information`,
              `Renseignements sur la sécurité`
            )}
            
          </b>
          <br />
          {enFr(
            `Ferring maintains a number of appropriate technical and organizational
            security measures to safeguard your personal data from unauthorized
            access, use, disclosure, accidental destruction or loss. For example,
            where appropriate, Ferring is committed to the encryption,
            anonymization or pseudonymization of personal data, and personal data
            will be stored securely in a designated system. Our internal policies
            and procedures are designed to prioritise and promote the protection
            of personal data. While our data security measures are continually
            evaluated and updated, the security of data transferred via the
            internet from a computer or other device cannot be guaranteed and you
            are encouraged to take steps to protect yourself online and against
            unauthorized use of your passwords, and mobile devices, as well as
            installing the appropriate firewall, anti-virus and anti-spyware
            protections for your computer. If you suspect any security compromise
            please notify us immediately via email at privacy@ferring.com.`,
            `Ferring maintient un certain nombre de mesures de sécurité techniques et organisationnelles appropriées pour protéger vos renseignements personnels contre l’accès, l’utilisation, la divulgation non autorisée, la destruction ou la perte accidentelle. Par exemple, selon les besoins, Ferring s’engage à chiffrer, à anonymiser ou à pseudonymiser les données personnelles, et les données personnelles sont conservées en lieu sûr dans un système désigné. Nos politiques et procédures internes sont conçues pour établir les priorités et promouvoir la protection des données personnelles.
            Bien que nos mesures de sécurité des données soient évaluées et mises à jour en continu, la sécurité des données transférées sur Internet à partir d’un ordinateur ou d’un autre appareil ne peut pas être garantie, et nous vous encourageons à prendre les mesures nécessaires pour vous protéger en ligne contre l’utilisation non autorisée de vos mots de passe et de vos appareils mobiles, ainsi qu’à installer un pare-feu, un antivirus et logiciel anti espion sur votre ordinateur. Si vous soupçonnez que la sécurité est compromise, veuillez nous en informer immédiatement par courriel à l’adresse privacy@ferring.com.
            `
          )}
          
        </p>

        {/* <p>
        <div id="children" className="anchor"/>
        <b>Children</b><br/>
        Ferring is committed to protecting the privacy of children. Where personal data relates to a data subject who is under the age of 18 (or less if provided for by the applicable national law), consent shall be required from their legal representative. Ferring shall make all available and reasonable efforts to verify that the necessary consent has been obtained in such cases.
        </p> */}

        <p>
          <div id="modifications" className="anchor" />
          <b>
          {enFr(
            `Modifications`,
            `Modifications`
          )}
          
          </b>
          <br />
          {enFr(
            `Any changes to this Privacy Notice will be communicated at this
            location. We recommend you consult our Privacy Notice periodically to
            review any changes and to keep informed about our ongoing commitment
            to respecting the right to privacy and to providing the highest
            possible level of data protection.`,
            `Les modifications apportées au présent énoncé de confidentialité seront communiquées sur ce site. Nous vous recommandons de consulter notre énoncé de confidentialité périodiquement pour vérifier les changements et rester informé de notre engagement continu à respecter la confidentialité et à offrir le plus haut degré possible de protection des données.`
          )}
          
        </p>

        <p>
          <div id="contact" className="anchor" />
          <b>
          {enFr(
            `Contact information`,
            `Coordonnées`
          )}
          
          </b>
          <br />
          {enFr(
            `If you have any questions or comments question regarding this Privacy
            Notice, or would like to get in touch regarding your rights as a data
            subject, please contact us:`,
            `Si vous avez des questions ou des commentaires au sujet du présent énoncé de confidentialité ou si vous souhaitez nous joindre concernant vos droits en tant que personne concernée, veuillez communiquer avec nous :`
          )}
          
          <ul>
            <li>
              {enFr(
                `By email the Global Privacy Office at `,
                `par courriel au Bureau mondial de la confidentialité, à l’adresse `
              )}
              {' '}
              <a href="mailto:privacy@ferring.com" target="_blank">
                privacy@ferring.com
              </a>
              {enFr(
                `, or`,
                `;`
              )}
              
            </li>
            <li>
              {enFr(
                <>
                  By mail to the following address:
                  <br />
                  <br />
                  Ferring International Center S.A.
                  <br />
                  Chemin De la Vergognausaz 50
                  <br />
                  1162 St Prex
                  <br />
                  Switzerland
                  <br />
                  This Privacy Notice was last updated on 23/05/2018
                </>,
                <>
                  par courrier à l’adresse suivante :
                  <br />
                  <br />

                  Ferring International Centre S.A.
                  <br />
                  Chemin de la Vergognausaz 50
                  <br />
                  1162 St Prex
                  <br />
                  Suisse
                  <br />
                  Le présent énoncé de confidentialité a été mis à jour le 23/05/2018.

                </>
              )}
              
            </li>
          </ul>
        </p>

        <h2>
          {enFr(
            `Ferring Website Usage Terms`,
            `Conditions d’utilisation du site Web de Ferring`
          )}
          
        </h2>

        <p>
          <b>
            {enFr(
              `Please READ and REVIEW the following TERMS AND CONDITIONS carefully
              before using this website.`,
              `Veuillez LIRE et EXAMINER attentivement les MODALITÉS avant d’entrer sur ce site Web.
              
              `
            )}
            
          </b>
        </p>
        <p>
          <b>
            {enFr(
              `By using this website, you agree to these terms and conditions. If
              you do not agree, please exit and disregard the information
              contained herein.`,
              `En utilisant ce site Web, vous acceptez les présentes modalités. Si vous ne les acceptez pas, veuillez quitter le site et ne pas tenir compte des renseignements qu’il contient.`
            )}
            
          </b>
        </p>

        <p>
          <b>
            {enFr(
              `General Information`,
              `Information générale`
            )}
            
            </b>
        </p>
        <p>
          {enFr(
            `This site contains material about Ferring International Center S.A.
            and its Affiliates, (hereinafter the Ferring Group"), which may be of
            interest to Ferring's employees and customers, as well as to members
            of the health care community and the general public. Affiliates shall
            mean any legal entity controlling, controlled by or under common
            control by or under common control with Ferring International Center
            S.A. Please feel free to browse this website. Your access and use of
            the information contained herein is subject to the following terms and
            conditions and all applicable laws. By accessing and browsing this web
            site, you accept, without limitation or qualification, these terms and
            conditions and acknowledge that they supersede any other agreement
            between you and Ferring. Ferring maintains this website for your
            personal use. This website and its contents are intended to comply
            with the laws and regulations of Switzerland. While you can access
            this web site from outside Switzerland, it is administered and managed
            in Switzerland.`,
            <>
              Ce site contient des renseignements à propos de Ferring International Center S.A. et de ses sociétés affiliées (ci-après le « groupe Ferring »), qui peuvent être intéressants pour les employés et les clients de Ferring, ainsi que pour les membres du milieu des soins de santé et le grand public. Le terme « sociétés affiliées » désigne une personne morale qui contrôle Ferring International Centre S.A., qui est contrôlée par celle-ci, qui est sous contrôle commun de celle-ci ou qui est soumise à un contrôle commun avec elle. La consultation de l’information contenue dans ce document et l’utilisation que vous en faites sont soumises aux modalités suivantes et à toutes les lois applicables. En accédant à ce site Web et en le parcourant, vous acceptez, sans limitation ni réserve, les présentes modalités et vous reconnaissez qu’elles ont préséance sur toute autre entente conclue entre vous et Ferring.
              <br/>
              Ferring tient à jour ce site Web pour votre usage personnel. Ce site Web et son contenu sont destinés à être conformes aux lois et aux règlements de la Suisse. Bien que vous puissiez accéder à ce site Web de l’extérieur de la Suisse, il est administré et géré en Suisse.
            </>
          )}
          
        </p>

        <p>
          <b>
            {enFr(
              `Limitation of Liability`,
              `Limitation de responsabilité`
            )}
            
          </b>
        </p>
        <p>
          {enFr(
            `Ferring will use reasonable efforts to include accurate, complete and
            up-to-date information on this web site, but Ferring makes no
            warranties or representations as to its accuracy, completeness or
            frequency that it is updated. All users agree that access to this web
            site is at their own risk, and that neither Ferring nor any party
            involved in creating or delivering this web site shall be liable for
            damages of any kind, including without limitation, any special, direct
            or indirect, incidental, or consequential or punitive damages (even if
            Ferring has been advised of the possibility of such damages) arising
            out of access to, or use of the information contained on this website,
            or any errors or omissions, misprints, out-of-date information,
            technical or pricing inaccuracies, typographical or other errors
            appearing on this website. This limitation includes damages to, or for
            any viruses that may infect your computer equipment.`,
            `Ferring déploie des efforts raisonnables pour présenter des renseignements exacts, complets et à jour sur ce site Web, mais elle ne donne aucune garantie ni ne fait aucune déclaration quant à leur exactitude, à leur exhaustivité ou à la fréquence des mises à jour. Tous les utilisateurs acceptent que l’accès à ce site Web soit à leurs propres risques et que ni Ferring ni aucune autre partie qui a participé à la création ou à la diffusion de ce site Web ne puisse être tenue responsable des dommages de toute nature, notamment les dommages particuliers, directs ou indirects, accessoires, consécutifs ou punitifs (même si Ferring a été informée de la possibilité de tels dommages), découlant de l’accès à ce site Web, de l’utilisation des renseignements qu’il contient ou de toute erreur ou omission, des coquilles, des données périmées, des inexactitudes techniques ou tarifaires, des erreurs typographiques ou de diverses erreurs figurant sur ce site Web. La présente limitation comprend les dommages que subit votre équipement informatique ou les virus qui peuvent l’infecter.`
          )}
          
        </p>

        <p>
          <b>
            {enFr(
              `Disclaimer`,
              `Avis de non-responsabilité`
            )}
            
          </b>
        </p>
        <p>
          {enFr(
            `Information and related materials are subject to change without
            notice. This website, and all information and related materials it
            contains, are provided "as is." Ferring makes no representation or
            warranty whatsoever regarding the completeness, accuracy,
            "up-to-dateness", or adequacy of, or the suitability, functionality,
            availability, or operation of this web site or the information or
            materials it contains. By using this website, you assume the risk that
            the information and materials on this website may be incomplete,
            inaccurate, out of date, or may not meet your needs and requirements.
            Ferring specifically disclaims all warranties, express or implied,
            including without limitation, the warranties or merchantability,
            fitness for a particular purpose, and non-infringement with respect to
            this website and information, graphics and materials contained herein.`,
            `Les renseignements et le matériel connexe peuvent être modifiés sans préavis. Ce site Web et tous les renseignements et documents connexes qu’il contient sont fournis « tels quels ». Ferring ne donne aucune garantie ni ne fait aucune déclaration de quelque nature que ce soit concernant l’exhaustivité, l’exactitude, l’actualité, la pertinence, la durabilité, la fonctionnalité, la disponibilité ou le fonctionnement de ce site Web, de l’information ou des documents qu’il contient. En utilisant ce site Web, vous assumez le risque que les renseignements et les documents de ce site soient incomplets, inexacts, périmés, ou qu’ils ne répondent pas à vos besoins et exigences. Ferring décline particulièrement toutes les garanties, expresses ou implicites, notamment les garanties de qualité marchande, d’adaptation à un usage particulier et d’absence de contrefaçon concernant ce site Web et l’information, les graphiques et le matériel contenus aux présentes.`
          )}
          
        </p>

        <p>
          <b>
          {enFr(
            `Trademarks`,
            `Marques de commerce`
          )}
          
          </b>
        </p>
        <p>
          {enFr(
            `All Ferring product trade names and logos appearing in this website,
            as designated with either a trademark symbol or type form that is
            different from the surrounding text, are trademarks of Ferring B.V.
            save where indicated otherwise. All third party product or trade names
            appearing in this website are the property of their respective owners
            and Ferring does not claim any ownership in them.`,
            `L’ensemble des appellations commerciales et des logos de produits apparaissant sur ce site Web, désignés soit par un symbole de marque de commerce soit par une forme différente du texte environnant, sont des marques de commerce de Ferring B.V., sauf indication contraire. L’ensemble des produits et des appellations commerciales tiers figurant sur ce site Web appartiennent à leurs propriétaires respectifs, et Ferring n’en revendique pas le droit de propriété.`
          )}
          
        </p>
        <p>
          <b>
            {enFr(
              `No License`,
              `Absence de licence`
            )}
            
          </b>
        </p>
        <p>
          {enFr(
            `Nothing in this website shall be construed as conferring by
            implication, estoppel or otherwise, any license or right to use in any
            form or manner any patent, copyright or trademark of Ferring. Please
            be advised that Ferring protects its intellectual property rights to
            the fullest extent of the law.`,
            `Rien dans ce site Web ne peut être interprété comme conférant implicitement, par préclusion ou autrement une licence ou un droit d’utiliser sous quelque forme ou de quelque manière que ce soit un brevet, un droit d’auteur ou une marque de commerce de Ferring. Veuillez noter que Ferring protège ses droits de propriété intellectuelle dans toute la mesure permise par la loi.`
          )}
          
        </p>
        <p>
          <b>
          {enFr(
            `Submissions (Information To Ferring)`,
            `Présentations (information transmise à Ferring)`
          )}
          
          </b>
        </p>
        <p>
          {enFr(
            `The submission of any unsolicited information, such as questions,
            comments, or suggestions, to Ferring, either through this website or
            by any other means of communication, shall NOT be considered
            confidential. Ferring shall have no obligation to you of any kind with
            respect to such information. By submitting any information to Ferring,
            you understand that Ferring shall be free to reproduce, use, disclose,
            display, exhibit, transmit, perform, create derivative works, and
            distribute the information to others without limitation, and to
            authorise others to do the same. In addition, Ferring shall be free to
            use any ideas, concepts, know-how or techniques contained in such
            information for any purpose whatsoever, including but not limited to,
            developing, manufacturing and marketing products and other items
            incorporating such ideas, concepts, know-how or techniques.`,
            `Toute présentation d’information non sollicitée, comme des questions, des commentaires ou des suggestions, adressée à Ferring, soit sur ce site Web ou par un autre moyen de communication, n’est PAS considérée comme confidentielle. Ferring n’a aucune obligation envers vous concernant cette information. En transmettant de l’information à Ferring, vous comprenez que celle-ci est libre de reproduire, d’utiliser, de divulguer, d’afficher, d’exposer, de transmettre, de mettre à profit cette information ou d’en créer des œuvres dérivées sans restriction, et d’autoriser d’autres personnes à faire de même. En outre, Ferring est libre d’utiliser les idées, les concepts, le savoir-faire ou les techniques contenus dans cette information à quelque fin que ce soit, notamment la conception, la fabrication et la commercialisation de produits et d’autres éléments intégrant ces idées, ces concepts, ce savoir-faire ou ces techniques.`
          )}
          
        </p>

        <p>
          <b>
          {enFr(
            `Other`,
            `Divers`
          )}
          
          </b>
        </p>
        <p>
          {enFr(
            `These website usage terms shall be governed by and construed in
            accordance with laws of Switzerland. If any provision herein is held
            to be unlawful, void, or for any reason unenforceable, then that
            provision shall be eliminated or limited to the minimum extent
            necessary and such elimination or limitation shall not affect the
            validity and enforceability of any remaining provisions. These website
            usage terms constitute the entire agreement between Ferring and you
            relating to the subject matter herein and you agree to indemnify
            Ferring for any claims or damages resulting from your failure to abide
            by these terms and conditions. The Ferring website may be unavailable
            from time to time due to mechanical, telecommunication, software,
            hardware and third-party vendor failures, updating or construction.
            Ferring cannot predict or control when such downtime may occur and
            cannot control the duration of or be held liable for any damages
            resulting from such downtime. Ferring reserves the right to alter or
            delete material from this web site at any time. Ferring may, at any
            time, revise these Web site Usage Terms by updating this posting. You
            are bound by any such revisions and should, therefore, periodically
            review these terms to review the then current Usage Terms.`,
            <>
            <p>
            Les présentes conditions d’utilisation du site Web sont régies et interprétées conformément aux lois de la Suisse. Si une disposition des présentes est jugée illégale, nulle ou pour quelque raison que ce soit inapplicable, cette disposition est limitée ou éliminée dans la mesure minimale nécessaire, et cette élimination ou limitation n’a pas d’incidence sur la validité et le caractère exécutoire des autres dispositions. Les présentes conditions d’utilisation du site Web constituent l’intégralité de l’entente conclue entre Ferring et vous concernant l’objet des présentes, et vous acceptez d’indemniser Ferring pour toute réclamation ou tout dommage découlant de votre non-respect des présentes modalités.
            </p>
            <p>
            Le site Web de Ferring peut être inaccessible de temps à autre en raison de défaillances mécaniques, logicielles ou matérielles ou de panne du système de télécommunication, ou de défaillances de fournisseurs tiers, de la mise à jour ou de la construction du site. Ferring ne peut pas prévoir ou prévenir l’apparition de tels temps d’arrêt et ne peut pas en contrôler la durée ni être tenue responsable des dommages en découlant.
            </p>
            <p>
            Ferring se réserve le droit de modifier ou de supprimer du contenu de ce site à tout moment. Ferring peut, à tout moment, réviser les conditions d’utilisation de ce site Web en mettant à jour la présente publication. Ces révisions vous lient, et vous devez par conséquent vérifier périodiquement les présentes conditions pour prendre connaissance de celles qui sont en vigueur.
            </p>

            </>
          )}
          
        </p>
        <p>
          {enFr(
            `Thank you for visiting the CardioUroConnect.ca website.`,
            `Merci de votre visite sur le site Web de CardioUroConnect.ca.`
          )}
          
          </p>
      </div>
      <Footer
        css={css`
        `}
      />
    </div>
  );
};
