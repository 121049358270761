import { useState, useCallback } from 'react';
import createUseContext from 'constate';

const savedLanguage = window.localStorage.getItem('language') || 'en';

export const useLanguage = createUseContext(() => {
  const [language, setLanguage] = useState(savedLanguage);
  const enFr = useCallback(
    (en, fr) => {
      if (en === undefined) {
        return language;
      }
      return language === 'en' ? en : fr;
    },
    [language],
  );

  const toggleLanguage = useCallback(() => {
    const newLanguage = language === 'en' ? 'fr' : 'en';
    setLanguage(newLanguage);
    window.localStorage.setItem('language', newLanguage);
  }, [language]);
  return { enFr, setLanguage, toggleLanguage, language };
});
