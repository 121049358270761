import React, { useState, useRef, useContext, useEffect } from 'react';
import { css } from '@emotion/core';
import { Button } from '~/components';
import { TemplatePreview } from '~/TemplatePreview';
import { generatePdf, useLanguage } from '~/utils';
import InteractionStateContainer from '../InteractionStateContainer';
import { tracker } from '~/utils/tracker';

function isElementInViewport(element) {
  var rect = element.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export const CardiologistListItem = ({
  cardiologist,
  userAddressString,
  ...props
}) => {
  const { enFr } = useLanguage();
  const [isGenerating, setIsGenerating] = useState(false);
  const [shouldShowPreview, setShouldShowPreview] = useState(false);
  const previewRef = useRef();
  const containerRef = useRef(null);

  const {
    businesses,
    focusTriggerSource,
    selectedBusinessId,
    businessIdInFocus,
    setBusinesses,
    focusOnBusinessId,
    setSelectedBusinessId,
  } = useContext(InteractionStateContainer.Context);

  useEffect(() => {
    if (
      businessIdInFocus === cardiologist.id &&
      focusTriggerSource !== 'list' &&
      containerRef.current &&
      !isElementInViewport(containerRef.current)
    ) {
      containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [businessIdInFocus, cardiologist.id, focusTriggerSource]);

  const cardioInfoForPdf =
    cardiologist.name +
    '\n' +
    cardiologist.address +
    (cardiologist.telephone
      ? `${cardiologist.address.endsWith('\n') ? '' : '\n'}t: ${
          cardiologist.telephone
        }`
      : '') +
    (cardiologist.fax ? `\nf: ${cardiologist.fax}` : '');

  return (
    <div
      ref={containerRef}
      onMouseEnter={() => focusOnBusinessId(cardiologist.id, 'list')}
      onMouseLeave={() => focusOnBusinessId(null, 'list')}
      onFocus={() => focusOnBusinessId(cardiologist.id, 'list')}
      onClick={() =>
        setSelectedBusinessId(
          selectedBusinessId === cardiologist.id ? null : cardiologist.id,
        )
      }
      css={css`
        display: flex;
        flex-direction: row;
        transition: all 0.1s;
        ${businessIdInFocus &&
          businessIdInFocus !== cardiologist.id &&
          // focusTriggerSource === 'map' &&
          css`
            filter: grayscale(1);
          `}
      `}
      {...props}
    >
      <img
        css={css`
          width: 180px;
          height: auto;
          object-fit: cover;
          @media screen and (max-width: 600px) {
            width: 130px;
          }
        `}
        src={require('~/assets/default_icon.png')}
        alt=""
      />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          background-color: #e6e3df;
          margin-left: 4px;
          padding: 20px;
          padding-bottom: 10px;
          font-size: 12px;
          p {
            margin-bottom: 0;
            line-height: 1.3;
          }
        `}
      >
        <p
          css={css`
            font-weight: bold;
            padding-bottom: 0.4em;
          `}
        >
          {cardiologist.name}
        </p>
        <p
          css={css`
            white-space: pre-wrap;
          `}
        >
          {cardiologist.address}
        </p>
        {cardiologist.telephone && (
          <p>
            {enFr(`T`, `Tél`)}: {cardiologist.telephone}
          </p>
        )}
        {cardiologist.fax && (
          <p
            css={css`
              display: block;
            `}
          >
            {enFr(`F`, `Téléc`)}: {cardiologist.fax}
          </p>
        )}
        <div
          css={css`
            margin-top: auto;
          `}
        />
        <Button
          css={css`
            font-size: 14px;
            &&& {
              margin-top: 10px;
              border-radius: 2px;

              @media screen and (max-width: 600px) {
                line-height: 1.5;
              }
            }
          `}
          onMouseEnter={() => setShouldShowPreview(true)}
          onMouseLeave={() => setShouldShowPreview(false)}
          onClick={async e => {
            e.stopPropagation();
            if (isGenerating) {
              return;
            }
            tracker.event('form', 'generate', cardiologist.name).send();
            setIsGenerating(true);
            await generatePdf(previewRef.current);
            setIsGenerating(false);
          }}
        >
          {isGenerating
            ? '...'
            : enFr(
                'Generate Requisition Form',
                'Générer le formulaire de demande',
              )}
        </Button>
        {shouldShowPreview && (
          <TemplatePreview
            css={css`
              /* opacity: 0; */
              position: fixed;
              top: -2000px;
              left: -2000px;
              /* pointer-events: none; */
            `}
            ref={previewRef}
            cardioInfo={cardioInfoForPdf}
            uroInfo={userAddressString}
          />
        )}
      </div>
    </div>
  );
};
